<!-- 
引入组件：import countDowm from '../countDown/countDown.vue' 
注册组件：
components: {
	countDowm
},

组件使用：<countDowm :index="index" :remainTime="item.time" @countDownEnd="countDownEnd"></countDowm>
index：下标
remainTime：倒计时事件（秒）
@countDownEnd：倒计时结束事件
 -->
<template>
	<div class="flex-row" :style="'font-size:'+ fontSize +'px;'">
		<div class="text-center radius5" v-if="day>0" :style="'width:' + width + 'px;height:'+ height +'px;line-height:' + height + 'px;background-color:'+bgColor +';color:' +numberColor+';font-weight:'+fontWeight">{{day}}</div>
		<div v-if="day>0&&showBold" :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' "  class="font-weight700">天</div>
		<div v-if="day>0&&!showBold" :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' " >天</div>
		<div v-if="showHours" class="text-center radius5" :style="'width:' + width + 'px;height:'+ height +'px;line-height:' + height + 'px;background-color:'+bgColor +';color:' +numberColor+';font-weight:'+fontWeight">{{hour? hourString : hourString}}</div>
		<div v-if="showHours&&showBold" :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' " class="font-weight700">时</div>
		<div v-if="showHours&&!showBold" :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' ">时</div>
		<div class="text-center radius5" :style="'width:' + width + 'px;height:'+ height +'px;line-height:' + height + 'px;background-color:'+bgColor +';color:' +numberColor+';font-weight:'+fontWeight">{{hour? minuteString : minuteString}}</div>
		<div v-if='showBold' :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' " class="font-weight700">分</div>
		<div v-if='!showBold' :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' " >分</div>
		<div class="text-center radius5" :style="'width:' + width + 'px;height:'+ height +'px;line-height:' + height + 'px;background-color:'+bgColor +';color:' +numberColor+';font-weight:'+fontWeight">{{hour? secondString : secondString}}</div>
		<div v-if='showBold' :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' " class="font-weight700">秒</div>
		<div v-if='!showBold' :style="'padding: 0 '+ paddingRL +'px;'+'color:'+colonColor + '; font-size:' + textFontSize + 'px;' ">秒</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				day: 1,
				hour: '',
				minute: '',
				second: '',
				promiseTimer: ''
			}
		},
		watch:{
			remainTime(newval,oldval){
				console.log('组件内部拿到值',this.remainTime)
				this.remainTime = newval
				this.start()
			}
		},
		props: {
			remainTime: { // 倒计时间总秒数
				type: Number,
				default: 0 
			},
			index: { // 下标
				type: Number,
				default: null
			},
			fontSize: { // 字体大小
				type: Number,
				default: 12
			},
			fontWeight: { // 数字粗细
				type: Number,
				default: 400
			},
      textFontSize: { // 文字大小
        type: Number,
				default: 16
      },
			paddingRL: { // 冒号左右边距
				type: Number,
				default: 1
			},
			width: { // 数字所在背景宽度
				type: Number,
				default: 18
			},
			height: { // 数字所在背景高度
				type: Number,
				default: 18
			},
			bgColor: { // 数字所在背景颜色
				type: String,
				default: '#F8E0F0'
			},
			numberColor: { // 数字颜色
				type: String,
				default: '#EA5078'
			},
			colonColor: { // 冒号颜色
				type: String,
				default: '#FFFFFF'
			},
			showHours: {
				type: Boolean,
				default: true
			},
			showBold: {
				type: Boolean,
				default: true
			}
		},
		created() {
			this.start()
		},
		methods: {
			start(){
				console.log('this.remainTime', this.remainTime);
				// debugger
				// debugger
				if (this.remainTime > 0) {
					this.day = Math.floor(this.remainTime / (24 * 3600 * 1000));
					var leave1 = this.remainTime % (24 * 3600 * 1000)
					this.hour = Math.floor(Math.floor(leave1 / (3600 * 1000)))
					var leave2 = leave1 % (3600 * 1000)
					this.minute = Math.floor(leave2 / (60 * 1000))
					var leave3 = leave2 % (60 * 1000)
					this.second = Math.floor(leave3 / 1000)
					this.countDowm()
				} else {
					this.day = 0
					this.hour = 0
					this.minute = 0
					this.second = 0
				}
			},
			
			
			countDowm() {
				var self = this
				clearInterval(this.promiseTimer)
				this.promiseTimer = setInterval(function() {
					// debugger
					if (self.hour === 0) {
						if (self.minute !== 0 && self.second === 0) {
							self.second = 59
							self.minute -= 1
						} else if (self.minute === 0 && self.second === 0) {
							self.second = 0
							self.$emit('countDownEnd', false, self.index)
							clearInterval(self.promiseTimer)
						} else {
							self.second -= 1
						}
					} else {
						if (self.minute !== 0 && self.second === 0) {
							self.second = 59
							self.minute -= 1
						} else if (self.minute === 0 && self.second === 0) {
							self.hour -= 1
							self.minute = 59
							self.second = 59
						} else {
							self.second -= 1
						}
					}
				}, 1000)
			},
			formatNum(num) {
				return num < 10 ? '0' + num : '' + num
			}
		},
		computed: {
			hourString() {
				return this.formatNum(this.hour)
			},
			minuteString() {
				return this.formatNum(this.minute)
			},
			secondString() {
				return this.formatNum(this.second)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.countDown-box {
		width: 18px;
		height: 18px;
		line-height: 18px;
		background-color: #F8E0F0;
		color: #EA5078;
	}
</style>
