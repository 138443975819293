<template>
  <!-- 优惠券领券 -->
  <div>
    <div class="collect margin-b-20 flex-row" @click="receiveClick">
      <div class="collect_left flex-row">
        <div class="left_text">
          <div class="left_text_top">领券中心</div>
          <div class="left_text_bottom">先领券再购物</div>
        </div>
        <div class="right_text flex-row-end-center">
          <div class="iconfont icon-jiantoux font-color-fff font-size30"></div>
        </div>
      </div>
      <div class="collect_center">
        <el-carousel :autoplay="false" arrow="always" height="149px" class="carousel">
          <el-carousel-item v-for="(item1,index1) in dataall" :key="index1">
            <div class="content flex-row-center-center">
              <div class="content_center flex-row-space-between-center">
                <div class="content_center_card flex-row margin-l-5" :style="index > 0 ? 'border-right: none' : ''"
                  v-for="(item,index) in item1" :key="index">
                  <div class="content_center_card_left">
                    <div class="content_title_center_card_left ">
                      <span class="color-FFE26F font-size22 " style="vertical-align: sub;">￥</span>
                      <span class="color-FFE26F font-size40 font-weight700 "
                        style="vertical-align: sub;color:#FFCC02">{{ item.fDeductionMoney }}</span>
                      <span class="font-size20" v-if="item.fDeductionMoney <= 999"
                        style="vertical-align: sub;">{{item.fCouponName.length > 3 ? item.fCouponName.slice(0,3) + '...' : item.fCouponName}}</span>
                    </div>
                    <div class="content_payment_center_card_left">仅限部分商品满{{item.fMinSaleMoney}}元使用</div>
                  </div>
                  <!-- <div v-if="item.fSurplusSum ==0 && item.fStatus == -1" style="width: 94px;height:94px;background: #e4e4e4;color:#999999;border-radius: 50%"
                    class="flex-column-center-center font-weight700 font-size17 text-center" >
                    已<br/>抢光
                  </div> -->
                  <div  style="width: 94px;height:94px;background: #FFD839;border-radius: 50%"
                    class="flex-column-center-center" >
                    <div class="font-weight700 font-size17">立即</div>
                    <div class="font-weight700 font-size17">领取</div>
                  </div>
                </div>
              </div>
            </div>

          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="collect_right flex-column-center-center">
        <div class="font-size20 font-color-fff font-weight700 margin-l-19 margin-b-5">领取</div>
        <div class="font-size20 font-color-fff font-weight700 margin-l-19">更多</div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  components: {
  },
  props: {
    dataall: {
      type: Array
    }
  },
  data () {
    return {

    }
  },
  created () {


  },
  methods: {
 
    receiveClick () {
      this.$emit('receiveClick')
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/.el-carousel__arrow--left {
  background: #ffd2c6;
  font-size: 25px;
  line-height: 36px;
}
/deep/.el-carousel__arrow--right {
  background: #ff764e;
  font-size: 25px;
  line-height: 36px;
}
.font-size70 {
  font-size: 70px;
}
.color-FFE26F {
  color: #ffe26f;
}
.iconfont {
  font-size: 30px;
}
.collect {
  width: 1100px;
  height: 148px;
  // border: 1px solid;
  background-image: url(../assets/collect.png);
  position: relative;
  left: 0px;
  top: 0px;
  .collect_left {
    width: 299px;
    height: 100%;
    // border: 1px solid;
    .left_text {
      margin-left: 40px;
      width: 121px;
      height: 100%;
      // border: 1px solid;
      .left_text_top {
        width: 100%;
        height: 50%;
        // border: 1px solid red;
        color: #ffffff;
        font-size: 30px;
        line-height: 101px;
        font-weight: 700;
      }
      .left_text_bottom {
        width: 100%;
        height: 50%;
        font-size: 16px;
        color: #ffffff;
        line-height: 45px;
        font-weight: 700;
      }
    }
    .right_text {
      width: 60px;
      height: 100%;
    }
  }
  .collect_center {
    width: 735px;
    height: 100%;
    // border: 1px solid;
    position: absolute;
    top: 0px;
    left: 235px;
    .carousel {
      /deep/.el-carousel__arrow--left {
        // background: red;
        position: absolute;
        // left: -10px;
        z-index: 111;
      }
    }
  }
  .collect_right {
    width: 186px;
    height: 100%;
    position: absolute;
    right: 0px;
    // border: 1px solid;
  }
  .content {
    // background: red;
    width: 100%;
    height: 148px;
    .content_center {
      width: 635px;
      height: 117px;
      background: #fff;
      // border: 1px solid;
      .content_center_card {
        width: 325px;
        height: 94px;
        border-right: 1px dashed gray;
        .content_center_card_left {
          width: 203px;
          height: 100%;
          margin-left: 5px;
          // border: 1px solid;
          .content_payment_center_card_left {
            height: 15px;
            width: 100%;
            font-size: 12px;
            color: #1e1e1e;
            padding-left: 5px;
            // border: 1px solid;
          }
          .content_title_center_card_left {
            height: 79px;
            line-height: 79px;
            width: 100%;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}
</style>