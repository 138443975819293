<!-- 说明：广告位暂时隐藏，因为目前接口还没好，等接口好了之后拿到广告 -->

<template>
	<div :class="themeClass" class="page-box flex-column-align-center">
		<el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false"
			:destroy-on-close="true" @close="playerVisible=false" style="text-align: center; width: 100%;"
			:before-close="handleClose">
			<span class="el-icon-circle-close closeicon" @click="handleClose"></span>
			<video id="player" width="100%" class="video-player vjs-custom-skin" :src="VideoSrc" controls autoplay
				:muted="false" loop></video>
		</el-dialog>
		<div class="main-nav height-400 flex-row-center-center margin-b-20 bg-000">
			<div class="main-nav-center height-400">
				<el-carousel height="400px" arrow="always">
					<el-carousel-item v-for="(item, index) in HomepageAdList" :key="'carousel' + index">
						<img @click="goHerf(item.fTargetUrl,item.fTargetTypeID,item.fPath,item.fVideoPath)"
							class="main-nav-center-carousel" :src="item.fVideoPath ? item.fVideoPath : item.fPath" />
						<!-- <video id="player"width="100%" class="video-player vjs-custom-skin" v-else :src="item.fVideoPath"
							:loop="true" :autoplay="false" :muted="true" ref="videoPlayer" :playsinline="true" :poster="item.fPath"
							@click="goHerf(item.fTargetTypeID,item.fVideoPath)"></video> -->
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="width-1100 flex-row-space-between">
				<div class="main-nav-left height-400 flex-colum bg-fff">
					<div class="main-nav-left-inner-box height-400 flex-colum bg-fff" >
						<div @mouseover="moveMainInFun(dataIndex)" @mouseleave="moveMainLeaveFun(dataIndex)"
							v-for="(data, dataIndex) in classifyList" :key="'mcf' + dataIndex"
							class="main-nav-left-item  flex-column-justify-center padding-lr-13 pointer padding-t-3"
							:class="{ 'font-color-fff bg-color-theme': data.checked == true  }"
							:style="{height:classifyList.length  >= 12 ? '28px' :leftheight }">
							<!-- <div :class="{ 'main-nav-left-before': dataIndex != 0 }"></div> -->
							<div class="flex-row margin-l-10 margin-b-10" style="margin: auto 1px;" :style="{ height:classifyList.length  >= 11 ? leftheight : '' , lineHeight: classifyList.length  >= 11 ? leftheight : '' }">
								<div class="margin-r-10"
									:class="{ 'color-theme': data.checked == false, 'font-color-fff': data.checked == true }">
									<!-- <div class="iconfont font-size18" :class="getIconName(data.fGoodsClass)">
									</div> -->
									<img :src="data.fPath" alt="" width="20" height="20" class="imgBac margin-l-10 margin-t-10"   />
								</div>
								<div style="line-height: 41px;font-weight:bold">{{ data.fGoodsClass }}</div>
							</div>
							<div class="flex-row hide-text">
								<!-- <div v-for="(item,indexC) in data.goodsClassProperties" :key="item+indexC" :class="{'main-nav-left-item-right-line':indexC != (data.goodsClassProperties.length - 1)}">
								{{item.fClassPropertie}}
								<div class="main-nav-left-item-right-line-before" v-if="indexC+1 != data.goodsClassProperties.length" :class="{'border-fff':data.checked == true}"></div>
							</div> -->
								<!-- <div class="main-nav-left-item-right-line">
									{{ data.goodsClassProperties[0].fClassPropertie }}
									<div class="main-nav-left-item-right-line-before"
										:class="{ 'border-fff': data.checked == true }">
									</div>
								</div>
								<div>{{ data.goodsClassProperties[1].fClassPropertie }}</div> -->
							</div>
						</div>
					</div>
					<transition name="el-zoom-in-left">
						<keep-alive>
							<div @mouseover="moveDetailInFun" @mouseleave="moveDetailLeaveFun"
								v-show="moveMainShow == true || moveDetailsShow == true"
								class="main-hover-box padding-10">
								<div v-for="(data, indexCo) in transList.item" :key="'cfo' + indexCo">
									<div class="main-hover-box-title bg-000 font-color-fff">{{ data.fClassPropertie }}
									</div>
									<div class="flex-row-align-center margin-t-10 flex-row-wrap">
										<div @click="testUrl(item)"
											v-for="(item, indexCi) in data.goodsClassPropertieDatas"
											:key="'ci' + indexCi"
											class="font-size12 font-color-666 margin-b-10 margin-r-15 pointer-color-theme">
											{{ item.fClassPropertieData }}
										</div>
									</div>
								</div>
							</div>
						</keep-alive>
					</transition>
				</div>
				<div class="main-nav-right flex-colum-between">
					<div class="main-nav-right-login-box bg-fff padding-lr-13 flex-row-align-center margin-t-10">
						<div v-if="getUserInfo" class="main-nav-right-login-avatar">
							<imageBox v-if="getUserInfo.fFileName" :src="getUserInfo.fFileName"></imageBox>
							<imageBox v-else :src="require('@/assets/personal.png')"></imageBox>
						</div>
						<div v-else class="main-nav-right-login-avatar">
							<imageBox :src="require('@/assets/personal.png')"></imageBox>
						</div>
						<div v-if="getUserInfo == null"
							class="main-nav-right-login-content font-size12 flex-colum-between margin-l-10 font-color-999">
							<div>
								你好，欢迎来到聚材通
							</div>
							<div class="newLaR">
								<div @click="jumpLogin" class="color-theme pointer">登录</div>
								<div style="color: #333333;margin-left: 5px;margin-right: 5px;">/</div>
								<div class="color-theme pointer"@click="register">注册</div>
							</div>
						</div>
						<div v-else
							class="main-nav-right-login-content font-size12 flex-colum-between margin-l-10 font-color-999">
							<div class="">
								<div class="flex-row-space-between">
									<div @click="jumpPersonal"
										class="text-decoration-underline color-theme pointer hide-text w100">
										{{ getUserInfo.customerName ? getUserInfo.customerName : '' }}
									</div>
									<div @click="LogOut" class="text-decoration-underline color-theme pointer">退出</div>
								</div>
								<!-- 游客登录 电话 -->
								<div v-if="getUserInfo.fCommerceTypeID == 0" class="font-color-666 margin-t-5">
									{{ getUserInfo.phone }}
								</div>
								<!-- 个人登录 电话 -->
								<div v-if="getUserInfo.fCommerceTypeID == 1" class="font-color-666 margin-t-5">
									{{ getUserInfo.phone }}
								</div>
								<!-- 企业管理员登录 电话 -->
								<div v-if="getUserInfo.fCommerceTypeID == 2 && getUserInfo.fIsManager == 1"
									class="font-color-666 margin-t-5">{{ getUserInfo.phone }}</div>
								<!-- 企业非管理员 用户名 -->
								<div v-if="getUserInfo.fCommerceTypeID == 2 && getUserInfo.fIsManager == 0"
									class="font-color-666">
									{{ getUserInfo.fUserName }}
								</div>
							</div>
							<!-- TODO 显示字段暂定 -->
							<div></div>
						</div>
					</div>
				
					<div class="main-nav-right-notice bg-fff padding-13 flex-colum-between ">
						<div class="flex-row-space-between">
							<div class="font-weight700">通知公告</div>
							<div class="color-theme pointer" @click="NoticeMore">更多</div>
						</div>

						<div class="main-nav-right-top font-size13" v-if="noticeList.length != 0" @click="NoticeMore">
							<el-carousel height="95px" direction="vertical" indicator-position="none">
								<!--去除轮播图指示器  indicator-position="none" -->
								<el-carousel-item v-for="(data, dataIndex) in noticeList" :key="'nl' + dataIndex">
									<div class="notice-box flex-colum-between">
										<div class="flex-row  font-size12"
											v-for="(item, index) in data" :key="'nli' + index">
											<div>【{{item.fAnnouncementType}}】</div>
											<div class="main-nav-right-top-title hide-text">
												{{ item.fTitle }}
											</div>
											<div class="font-color-666">{{ item.fCreateTime }}</div>
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
						<div style="height: 57px " class="font-color-666 font-size12 text-center" v-else>暂无通知公告</div>

					</div>
					<div class="main-nav-right-model bg-fff padding-13 flex-colum-between margin-b-10">
						<div class="flex-row-space-between">
							<div class="font-weight700">常用功能</div>
							<div @click="jumpSet" class="color-theme pointer">设置</div>
						</div>
						<div
							class="main-nav-right-model-carousel-box main-nav-right-model-carousel box-row-carousel-one">
							<el-carousel height="90px" :autoplay="false" arrow="hover" :loop="false">
								<el-carousel-item v-for="(list, indexU) in empOftenApplication" :key="'box' + indexU">
									<div class="main-nav-right-model-carousel flex-row-wrap ">
										<div @click="modularJumpPage(item.fApplicationName)"
											v-for="(item, index) in list" :key="'list' + index"
											class="main-nav-right-bottom-item flex-column-center-center pointer">
											<!-- <svg class="icon" aria-hidden="true" style="width: 20px;height: 20px;">
												<use :xlink:href="'#'+item.fIconClass"></use>
											</svg> -->
											<div style="width: 23px;height: 23px;"><img :src="item.fPath" alt=""
													width="23" height="23" />
											</div>
											<div class="font-color-333 font-size12 margin-t-3">{{ item.title }}</div>
										</div>
										<div v-if="list.length % 3 != 0" class="main-nav-right-bottom-item"></div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- svg 例子 -->
		<!-- <div class="flex-row-center-center">
			<svg-icon icon-class="DownloadFailed" />
		</div> -->

		<!-- <svg class="icon" aria-hidden="true">
		  <use  :xlink:href="testimage"></use>
		</svg> -->
		<!-- 抽奖活动 -->
		<div v-if="drawAwardPath" class="box-advert width-1100 bg-F2F2F2 flex-row-center-center margin-b-20 pointer">
			<img :src="drawAwardPath" style="height: 90px;" class="width-1100" @click="toDrawAward" />
		</div>
		<!-- 问卷调查 -->
		<div v-if="questionnairePath"
			class="box-advert width-1100 bg-F2F2F2 flex-row-center-center margin-b-20 pointer">
			<img :src="questionnairePath" style="height: 90px;" class="width-1100" @click="toQuestionnaire" />
		</div>
		<!-- 领券中心 -->
    <div v-if="couponList.length > 0" >
    <CollectCard :dataall = "dataall" @receiveClick ="receiveClick"/>
    </div>
		<!-- 活动板块的列表 -->
		<div class="box-one bg-fff margin-b-20 padding-20" v-if="isshowhuodong">
			<div class="flex-row-space-between">
				<div
					style="position: relative;width: 100%; height: 40px;margin-bottom: 10px;line-height: 40px;padding: 0 10px;">
					{{huodongbgpath}}
					<img v-if="huodongbgpath != '' && huodongbgpath != null && huodongbgpath != undefined"
						:src="huodongbgpath" alt=""
						style="position: absolute;left: 0;top: 0;z-index: 1;width: 100%;height: 100%;">
					<div style="position: absolute;z-index: 2;width: 100%;top: 0;">
						<div class="font-size18 font-weight700" style="display: flex; flex-direction: row;">
							{{huodongtitle}}
						</div>
						<div class="flex-row-align-center pointer " style="position: absolute;right: 10px;top: 0px;"
							@click="LimitedActivities">
							<span
								class="font-size24 font-weight700 font-color-FF0000 margin-r-10">{{showhuodongtext}}</span>
							<countDowm :index="1" :remainTime="countDownTimeactivity"
								@countDownEnd="countDownEndactivity" :width="22" :height="22" :fontWeight="700"
								:fontSize="14"
								style="margin-left: 10px;margin-top: 0px;margin-right: 10px; line-height: 22px;"
								numberColor="#fff" bgColor="#ff0000" colonColor="#ff0000" :paddingRL="4"></countDowm> 更多
							<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
						</div>
					</div>

				</div>
			</div>
			<div class="flex-row-space-between-center margin-t-10">
				<div class="box-one-row-item-full-image margin-r-15"
					v-if=" huodongtitleimg != '' && huodongtitleimg != undefined && huodongtitleimg != null">
					<img class="box-one-row-full-image" :src="huodongtitleimg"></img>
				</div>
				<div class="box-one-row-carousel box-row-carousel-two">
					<el-carousel height="260px" :autoplay="false" arrow="always" :loop="false">
						<el-carousel-item v-for="(list, indexList) in huodongList" :key="indexList">
							<div class="flex-row-align-center">
								<div class="box-one-row-item-box pointer" :class="{'margin-r-10':(indexList+1)%5!=0}"
									v-for="(item1, indexItem1) in list" :key="indexItem1"
									@click="jumpGoodDetails(item1)">
									<div class="box-one-row-item flex-colum-space-between">

										<div class="box-one-row-picture-box flex-row-center-center"
											style="position: relative;">
											<!-- 0右上角 -->
											<div class="box-one-row-buoy"
												v-if="huodongimgisarea == 0 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
												<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
											</div>
											<!-- 1右下角 -->
											<div class="box-one-row-buoy1"
												v-if="huodongimgisarea == 1 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
												<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
											</div>
											<!-- 2左下角 -->
											<div class="box-one-row-buoy2"
												v-if="huodongimgisarea == 2 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
												<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
											</div>
											<!-- 3左上角 -->
											<div class="box-one-row-buoy3"
												v-if="huodongimgisarea == 3 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
												<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
											</div>


											<img class="box-one-row-picture" :src="item1.goods.fPicturePath"></img>



										</div>
										<div class="box-one-row-content-box padding-10 flex-colum-between">
											<div class="box-one-row-content-title font-size13 twoline-show-hide">
												{{ item1.goods.fGoodsFullName }}
											</div>
											<div class="box-one-row-content-price font-size16 font-weight700">
												￥ <span
													v-if='item1.goods.fGoodsMinPrice == item1.goods.fGoodsPrice ? false:true'>{{ item1.goods.fGoodsMinPrice }}~</span><span>{{ item1.goods.fGoodsPrice }}</span>
												元/{{ item1.goods.fUnitName }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
    <div v-if="couponshopList.length > 0" >
      <CollectCardshop :dataall="datashopall" @receiveClick ="receiveSHopClick"/>
    </div>
		<!-- 店铺活动板块的列表 -->
		<div class="box-one bg-fff margin-b-20 padding-20" v-if="Shopisshowhuodong">
			<div class="flex-row-space-between">
				<div
					style="position: relative;width: 100%; height: 40px;margin-bottom: 10px;line-height: 40px;padding: 0 10px;">
					{{Shophuodongbgpath}}
					<img v-if="Shophuodongbgpath != '' && Shophuodongbgpath != null && Shophuodongbgpath != undefined"
						:src="Shophuodongbgpath" alt=""
						style="position: absolute;left: 0;top: 0;z-index: 1;width: 100%;height: 100%;">
					<div style="position: absolute;z-index: 2;width: 100%;top: 0;">
						<div class="font-size18 font-weight700" style="display: flex; flex-direction: row;">
							{{Shophuodongtitle}}
						</div>
						<div class="flex-row-align-center pointer " style="position: absolute;right: 10px;top: 0px;"
							@click="LimitedshopActivities">
							<span
								class="font-size24 font-weight700 font-color-FF0000 margin-r-10">{{Shopshowhuodongtext}}</span>
							<countDowm :index="1" :remainTime="ShopcountDownTimeactivity"
								@countDownEnd="ShopcountDownEndactivity" :width="22" :height="22" :fontWeight="700"
								:fontSize="14"
								style="margin-left: 10px;margin-top: 0px;margin-right: 10px; line-height: 22px;"
								numberColor="#fff" bgColor="#ff0000" colonColor="#ff0000" :paddingRL="4"></countDowm> 更多
							<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
						</div>
					</div>

				</div>
			</div>
			<div class="flex-row-space-between-center margin-t-10">
				<div class="box-one-row-item-full-image margin-r-15"
					v-if=" Shophuodongtitleimg != '' && Shophuodongtitleimg != undefined && Shophuodongtitleimg != null">
					<img class="box-one-row-full-image" :src="Shophuodongtitleimg"></img>
				</div>
				<div class="box-one-row-carousel box-row-carousel-two">
					<el-carousel height="260px" :autoplay="false" arrow="always" :loop="false">
						<el-carousel-item v-for="(list, indexList) in ShophuodongList" :key="indexList">
							<div class="flex-row-align-center">
								<div class="box-one-row-item-box pointer" :class="{'margin-r-10':(indexList+1)%5!=0}"
									v-for="(item1, indexItem1) in list" :key="indexItem1"
									@click="jumpGoodDetails(item1)">
									<div class="box-one-row-item flex-colum-space-between">

										<div class="box-one-row-picture-box flex-row-center-center"
											style="position: relative;">
											<!-- 0右上角 -->
											<div class="box-one-row-buoy"
												v-if="Shophuodongimgisarea == 0 && ShophuodongImg != '' && ShophuodongImg != undefined && ShophuodongImg != null">
												<img :src="ShophuodongImg" alt="" style="width: 100%;height: 100%;">
											</div>
											<!-- 1右下角 -->
											<div class="box-one-row-buoy1"
												v-if="Shophuodongimgisarea == 1 && ShophuodongImg != '' && ShophuodongImg != undefined && ShophuodongImg != null">
												<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
											</div>
											<!-- 2左下角 -->
											<div class="box-one-row-buoy2"
												v-if="Shophuodongimgisarea == 2 && ShophuodongImg != '' && ShophuodongImg != undefined && ShophuodongImg != null">
												<img :src="ShophuodongImg" alt="" style="width: 100%;height: 100%;">
											</div>
											<!-- 3左上角 -->
											<div class="box-one-row-buoy3"
												v-if="Shophuodongimgisarea == 3 && ShophuodongImg != '' && ShophuodongImg != undefined && ShophuodongImg != null">
												<img :src="ShophuodongImg" alt="" style="width: 100%;height: 100%;">
											</div>


											<img class="box-one-row-picture" :src="item1.goods.fPicturePath"></img>



										</div>
										<div class="box-one-row-content-box padding-10 flex-colum-between">
											<div class="box-one-row-content-title font-size13 twoline-show-hide">
												{{ item1.goods.fGoodsFullName }}
											</div>
											<div class="box-one-row-content-price font-size16 font-weight700">
												￥ <span
													v-if='item1.goods.fGoodsMinPrice == item1.goods.fGoodsPrice ? false:true'>{{ item1.goods.fGoodsMinPrice }}~</span><span>{{ item1.goods.fGoodsPrice }}</span>
												元/{{ item1.goods.fUnitName }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
    <!-- 店铺活动板块的列表 -->
		<div class="box-advert width-1100 bg-F2F2F2 flex-row-center-center margin-b-20" v-show="false">广告位</div>

		<div class="box-two width-1100 margin-b-20 flex-colum-between" v-if="integralList.length == 0 ? false : true">
			<div class="box-two-top width-1100">
				<div class="box-two-top-content flex-row-space-between-center">
					<div class="font-weight700 font-size18 flex-row-align-center">
						<div class="font-color-FF0000 font-size22"></div>
						<div class="box-two-top-slash font-color-FF0000 font-size22"></div>
						积分兑水泥
					</div>
					<div class="flex-row-align-center">
						<div class="font-size24 font-weight700 font-color-FF0000 margin-r-10"
							style="line-height: 30px;">
							{{ isDistanceStartTime ? '距开始:' : '距结束:' }}
						</div>
						<countDowm :index="1" :remainTime="countDownTime" @countDownEnd="countDownEnd" :width="22"
							:height="22" :fontWeight="700" :fontSize="14" numberColor="#fff" bgColor="#ff0000"
							colonColor="#ff0000" :paddingRL="4">
						</countDowm>
						<div class="flex-row-align-center margin-l-10 pointer " @click="LimitedTimeActivities">
							更多
							<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="box-two-row-carousel box-row-carousel-two">
				<el-carousel height="245px" :autoplay="false" arrow="always" :loop="false" :class="this.showbgimg  == 0 ? 'carouselbg' : 
        this.showbgimg  == 1 ? 'carouselbg1' : this.showbgimg  == 2 ? 'carouselbg2' : this.showbgimg  == 3 ? 'carouselbg3' :  this.showbgimg  == 4 ? 'carouselbg4' :this.showbgimg  == 5 ? 'carouselbg5' : ''">
					<el-carousel-item v-for="(item, indexItem) of integralList" :key="indexItem">
						<div class="flex-row-align-center">
              
							<div class="box-two-item-box padding-10 font-size12 flex-colum-space-between pointer"
								:class="{ 'margin-r-10': (indexItem + 1) % 6 != 0 }"
								v-for="(item11, indexItem11) of item" :key="indexItem11"
								@click="toIntegralDetails(item11)">
								<!-- 图片 -->
								<div class="box-two-picture-box flex-row-center-center position-relative">
									<!-- 0右上角 -->
									<div class="box-one-row-buoy"
										v-if="item11.couponInfo != null&& huodongimgisarea == 0 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
										<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
									</div>
									<!-- 1右下角 -->
									<div class="box-one-row-buoy1"
										v-if="item11.couponInfo != null&& huodongimgisarea == 1 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
										<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
									</div>
									<!-- 2左下角 -->
									<div class="box-one-row-buoy2"
										v-if="item11.couponInfo != null&& huodongimgisarea == 2 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
										<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
									</div>
									<!-- 3左上角 -->
									<div class="box-one-row-buoy3"
										v-if="item11.couponInfo != null&& huodongimgisarea == 3 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
										<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
									</div>

									<img class="box-two-picture" :src="item11.fGoods.fPicturePath" />
								</div>
								<!-- 标题 -->
								<div class="box-two-title twoline-show font-size13 twoline-show-hide">
									{{ item11.fGoods.fGoodsFullName }}
								</div>
								<!-- 积分 -->
								<div class="box-two-integral font-size16 font-weight700 font-color-FF0000">
									<span class="iconfont icon-jinbi1"></span>
									{{ item11.fPointSwapGoodsPrice.fPointPrice }}
									<span class="font-size12">积分/{{ item11.fGoods.fUnitName }}</span>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>

    <!-- 家装水泥推荐 -->
		<div class="box-four width-1100 bg-fff margin-b-20 padding-20" v-if="homeDecoration.length > 0">
			<div class="flex-row-space-between">
				<div class="font-size18 font-weight700">家装水泥推荐</div>
				<div class="flex-row-align-center pointer " @click="toGoods(homeDecoration[0].goods.fGoodsClassID)">
					更多
					<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
				</div>
			</div>
			<div class="box-four-row">
				<div class="box-four-item-box flex-row-wrap width-fill">
					<!--  :class="{'flex-row-align-center':homeDecoration.length % 5 != 0, 'flex-row-space-between-center':homeDecoration.length % 5 == 0}" -->
					<div @click="jumpGoodDetails(item)"
						class="box-four-item flex-column-start border-F2F2F2 margin-b-10"
						:class="{ 'margin-r-10': (index + 1) % 5 != 0 }" v-for="(item, index) in homeDecoration"
						:key="index" v-if="
							couponList.length > 0 && integralList.length > 0
								? index <= 4
								: (couponList.length == 0 && integralList.length > 0) || (couponList.length > 0 && integralList.length == 0)
								? index <= 9
								: couponList.length == 0 && integralList.length == 0
								? index <= 14
								: ''
						">
						<!-- 列表图片 -->
						<div class="box-four-picture-box flex-row-center-center pointer position-relative">
							<!-- 0右上角 -->
							<div class="box-one-row-buoy"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 0 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 1右下角 -->
							<div class="box-one-row-buoy1"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 1 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 2左下角 -->
							<div class="box-one-row-buoy2"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 2 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 3左上角 -->
							<div class="box-one-row-buoy3"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 3 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!--是否已售罄-->
							<div v-if='item.goods.fStatus == 2 ? true:false' class='box-sellout'>
								<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
							</div>

							<img class="box-four-picture" style="width: 202px;height: 202px;"
								:src="item.goods.fPicturePath" />

						</div>
						<!-- 列表内容 -->
						<div class="flex-colum-between padding-5">
							<div class="box-four-content-title twoline-show font-size13">{{ item.goods.fGoodsFullName }}
							</div>
							<div class="box-four-content-price font-size12">
								¥
								<span class="font-size16 font-weight700"><span
										v-if='item.goods.fGoodsMinPrice == item.goods.fGoodsPrice ? false:true '>{{ item.goods.fGoodsMinPrice }}~</span><span>{{ item.goods.fGoodsPrice }}</span></span>
								元/{{ item.goods.fUnitName }}
							</div>
							<div class="box-four-content-type-box margin-tb-4">
								<!-- 自营 TODO 字段待确认 -->
								<!-- <div class="box-four-content-type font-size12 font-color-fff">{{ item.type }}</div> -->
								<div class="box-four-content-type font-size12 font-color-fff"
									v-if="item.goods.fShopTypeID == '1'">{{ item.goods.fShopTypeName }}</div>
								<!-- 积分  目前没有 -->
								<div class="box-four-content-integral font-size12 font-color-FF0000"
									v-if="item.goods.fActivityIntegralMultiple > 1">
									{{ item.goods.fActivityIntegralMultiple }}倍积分
								</div>
							</div>
							<div class="box-four-content-shop font-size12  flex-row-align-center pointer ">
								<div class="box-four-content-shop-text hide-text font-color-ccc margin-r-10">
									{{ item.goods.fShopName }}
								</div>
								进店
								<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- 水泥推荐 -->
		<div class="box-four width-1100 bg-fff margin-b-20 padding-20" v-if="hotCementGoodsList.length > 0">
			<div class="flex-row-space-between">
				<div class="font-size18 font-weight700">水泥推荐</div>
				<div class="flex-row-align-center pointer " @click="toGoods(hotCementGoodsList[0].goods.fGoodsClassID)">
					更多
					<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
				</div>
			</div>
			<div class="box-four-row">
				<div class="box-four-item-box flex-row-wrap width-fill">
					<!--  :class="{'flex-row-align-center':hotCementGoodsList.length % 5 != 0, 'flex-row-space-between-center':hotCementGoodsList.length % 5 == 0}" -->
					<div @click="jumpGoodDetails(item)"
						class="box-four-item flex-column-start border-F2F2F2 margin-b-10"
						:class="{ 'margin-r-10': (index + 1) % 5 != 0 }" v-for="(item, index) in hotCementGoodsList"
						:key="index" v-if="
							couponList.length > 0 && integralList.length > 0
								? index <= 4
								: (couponList.length == 0 && integralList.length > 0) || (couponList.length > 0 && integralList.length == 0)
								? index <= 9
								: couponList.length == 0 && integralList.length == 0
								? index <= 14
								: ''
						">
						<!-- 列表图片 -->
						<div class="box-four-picture-box flex-row-center-center pointer position-relative">
							<!-- 0右上角 -->
							<div class="box-one-row-buoy"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 0 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 1右下角 -->
							<div class="box-one-row-buoy1"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 1 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 2左下角 -->
							<div class="box-one-row-buoy2"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 2 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 3左上角 -->
							<div class="box-one-row-buoy3"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 3 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!--是否已售罄-->
							<div v-if='item.goods.fStatus == 2 ? true:false' class='box-sellout'>
								<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
							</div>

							<img class="box-four-picture" style="width: 202px;height: 202px;"
								:src="item.goods.fPicturePath" />

						</div>
						<!-- 列表内容 -->
						<div class="flex-colum-between padding-5">
							<div class="box-four-content-title twoline-show font-size13">{{ item.goods.fGoodsFullName }}
							</div>
							<div class="box-four-content-price font-size12">
								¥
								<span class="font-size16 font-weight700"><span
										v-if='item.goods.fGoodsMinPrice == item.goods.fGoodsPrice ? false:true '>{{ item.goods.fGoodsMinPrice }}~</span><span>{{ item.goods.fGoodsPrice }}</span></span>
								元/{{ item.goods.fUnitName }}
							</div>
							<div class="box-four-content-type-box margin-tb-4">
								<!-- 自营 TODO 字段待确认 -->
								<!-- <div class="box-four-content-type font-size12 font-color-fff">{{ item.type }}</div> -->
								<div class="box-four-content-type font-size12 font-color-fff"
									v-if="item.goods.fShopTypeID == '1'">{{ item.goods.fShopTypeName }}</div>
								<!-- 积分  目前没有 -->
								<div class="box-four-content-integral font-size12 font-color-FF0000"
									v-if="item.goods.fActivityIntegralMultiple > 1">
									{{ item.goods.fActivityIntegralMultiple }}倍积分
								</div>
							</div>
							<div class="box-four-content-shop font-size12  flex-row-align-center pointer ">
								<div class="box-four-content-shop-text hide-text font-color-ccc margin-r-10">
									{{ item.goods.fShopName }}
								</div>
								进店
								<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 零售水泥推荐为其他建材推荐 -->
		<div class="box-four width-1100 bg-fff margin-b-20 padding-20" v-if="hotDistributorList.length > 0">
			<div class="flex-row-space-between">
				<div class="font-size18 font-weight700">其它建材推荐</div>
				<div class="flex-row-align-center pointer " @click="toGoods(hotDistributorList[0].goods.fGoodsClassID)">
					更多
					<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
				</div>
			</div>
			<div class="box-four-row">
				<div class="box-four-item-box flex-row-wrap width-fill">
					<!--  :class="{'flex-row-align-center':hotDistributorList.length % 5 != 0, 'flex-row-space-between-center':hotDistributorList.length % 5 == 0}" -->
					<div @click="jumpGoodDetails(item)"
						class="box-four-item flex-column-start border-F2F2F2 margin-b-10"
						:class="{ 'margin-r-10': (index + 1) % 5 != 0 }" v-for="(item, index) in hotDistributorList"
						:key="index" v-if="
    					couponList.length > 0 && integralList.length > 0
    						? index <= 4
    						: (couponList.length == 0 && integralList.length > 0) || (couponList.length > 0 && integralList.length == 0)
    						? index <= 9
    						: couponList.length == 0 && integralList.length == 0
    						? index <= 14
    						: ''
    				">
						<!-- 列表图片 -->
						<div class="box-four-picture-box flex-row-center-center pointer position-relative">
							<!-- 0右上角 -->
							<div class="box-one-row-buoy"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 0 &&item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 1右下角 -->
							<div class="box-one-row-buoy1"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 1 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 2左下角 -->
							<div class="box-one-row-buoy2"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 2 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 3左上角 -->
							<div class="box-one-row-buoy3"
								v-if="item.goods.couponInfo != null&& item.goods.couponInfo.fDispArea == 3 && item.goods.couponInfo.fIconPath != '' && item.goods.couponInfo.fIconPath != undefined && item.goods.couponInfo.fIconPath != null">
								<img :src="item.goods.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
							</div>
							<!--是否已售罄-->
							<div v-if='item.goods.fStatus == 2 ? true:false' class='box-sellout'>
								<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
							</div>

							<img class="box-four-picture" style="width: 202px;height: 202px;"
								:src="item.goods.fPicturePath" />

						</div>
						<!-- 列表内容 -->
						<div class="flex-colum-between padding-5">
							<div class="box-four-content-title twoline-show font-size13">{{ item.goods.fGoodsFullName }}
							</div>
							<div class="box-four-content-price font-size12">
								¥
								<span class="font-size16 font-weight700"><span
										v-if='item.goods.fGoodsMinPrice == item.goods.fGoodsPrice ? false:true '>{{ item.goods.fGoodsMinPrice }}~</span><span>{{ item.goods.fGoodsPrice }}</span></span>
								元/{{ item.goods.fUnitName }}
							</div>
							<div class="box-four-content-type-box margin-tb-4">
								<!-- 自营 TODO 字段待确认 -->
								<!-- <div class="box-four-content-type font-size12 font-color-fff">{{ item.type }}</div> -->
								<div class="box-four-content-type font-size12 font-color-fff"
									v-if="item.goods.fShopTypeID == '1'">{{ item.goods.fShopTypeName }}</div>
								<!-- 积分  目前没有 -->
								<div class="box-four-content-integral font-size12 font-color-FF0000"
									v-if="item.goods.fActivityIntegralMultiple > 1">
									{{ item.goods.fActivityIntegralMultiple }}倍积分
								</div>
							</div>
							<div class="box-four-content-shop font-size12  flex-row-align-center pointer ">
								<div class="box-four-content-shop-text hide-text font-color-ccc margin-r-10">
									{{ item.goods.fShopName }}
								</div>
								进店
								<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 商砼推荐 -->
		<div class="box-four width-1100 bg-fff margin-b-20 padding-20" v-if="hotConcreteGoodsList.length > 0">
			<div class="flex-row-space-between">
				<div class="font-size18 font-weight700">商砼推荐</div>
				<div class="flex-row-align-center pointer "
					@click="toGoods(hotConcreteGoodsList[0].goods.fGoodsClassID)">
					更多
					<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
				</div>
			</div>
			<div class="box-four-row flex-row-start-start">
				<div class="box-four-item-box"
					:class="{ 'flex-row-align-center': hotConcreteGoodsList.length % 5 != 0, 'flex-row-space-between-center': hotConcreteGoodsList.length % 5 == 0 }">
					<div @click="jumpGoodDetails(item)" class="box-four-item flex-column-start border-F2F2F2"
						:class="{ 'margin-r-10': hotConcreteGoodsList.length % 5 != 0 }"
						v-for="(item, index) in hotConcreteGoodsList" :key="'11' + index">
						<!-- 列表图片 -->
						<div class="box-four-picture-box flex-row-center-center pointer position-relative">
							<img class="box-four-picture" style="width: 202px;height: 202px;"
								:src="item.goods.fPicturePath" />
							<!-- 0右上角 -->
							<div class="box-one-row-buoy"
								v-if="item.goods.couponInfo != null&& huodongimgisarea == 0 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
								<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 1右下角 -->
							<div class="box-one-row-buoy1"
								v-if="item.goods.couponInfo != null&& huodongimgisarea == 1 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
								<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 2左下角 -->
							<div class="box-one-row-buoy2"
								v-if="item.goods.couponInfo != null&& huodongimgisarea == 2 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
								<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
							</div>
							<!-- 3左上角 -->
							<div class="box-one-row-buoy3"
								v-if="item.goods.couponInfo != null&& huodongimgisarea == 3 && huodongImg != '' && huodongImg != undefined && huodongImg != null">
								<img :src="huodongImg" alt="" style="width: 100%;height: 100%;">
							</div>
							<!--是否已售罄-->
							<div v-if='item.goods.fStatus == 2 ? true:false' class='box-sellout'>
								<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
							</div>
						</div>
						<!-- 列表内容 -->
						<div class="flex-colum-between padding-5">
							<div class="box-four-content-title twoline-show font-size13">{{ item.goods.fGoodsFullName }}
							</div>
							<div class="box-four-content-price font-size12">
								¥
								<span class="font-size16 font-weight700"><span
										v-if='item.goods.fGoodsMinPrice == item.goods.fGoodsPrice ? false:true '>{{ item.goods.fGoodsMinPrice }}~</span><span>{{ item.goods.fGoodsPrice }}</span></span>
								元/{{ item.goods.fUnitName }}
							</div>
							<div class="box-four-content-type-box margin-tb-4">
								<!-- 自营 TODO 字段待确认 -->
								<!-- <div class="box-four-content-type font-size12 font-color-fff">{{ item.type }}</div> -->
								<div class="box-four-content-type font-size12 font-color-fff"
									v-if="item.goods.fShopTypeID == '1'">{{ item.goods.fShopTypeName }}</div>
								<!-- 积分  目前没有 -->
								<div class="box-four-content-integral font-size12 font-color-FF0000"
									v-if="item.goods.fActivityIntegralMultipl > 1">
									{{ item.goods.fActivityIntegralMultiple }}
								</div>
							</div>
							<div class="box-four-content-shop font-size12  flex-row-align-center pointer ">
								<div class="box-four-content-shop-text hide-text font-color-ccc margin-r-10">
									{{ item.goods.fShopName }}
								</div>
								进店
								<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 推荐店铺,合作品牌 -->
		<div class="width-1100 flex-row-space-between margin-b-20">
			<div class="box-three bg-fff padding-13 flex-colum-between">
				<div class="flex-row-space-between margin-b-10">
					<div class="font-size18 font-weight700">推荐店铺
						<!-- <span style="color: #016bed;font-size: 16px;">({{ hSlength }}家)</span> -->
					</div>
					<div class="flex-row-align-center pointer " @click="toShopList">
						更多
						<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
					</div>
				</div>
				<div class="box-three-carousel box-row-carousel-shop">
					<el-carousel height="168px" :autoplay="false" arrow="always" :loop="false"
						indicator-position="outside">
						<el-carousel-item v-for="(list, indexList) in hotShop" :key="indexList">
							<div class="" style="display: flex;flex-wrap: wrap;">
								<div v-for="(item, indexItem) in list" :key="indexItem" @click="enterTheStore(item)"
									class="box-three-carousel-item-shop box-three-carousel-item-shop-bg-one flex-row-space-between-center pointer"
									:class="{ 'box-three-carousel-item-shop-bg-one': item.type == 'userShop'}"
									style="justify-content: unset;">
									<img class="margin-r-10"
										:class="{ 'box-three-carousel-item-shop-img': item.isImage != 'false'}"
										:src="item.img == '' ? imgurl : item.img" />
									<div class="flex-colum-between">
										<el-tooltip :content="item.title" placement="top">
											<div
												class="box-three-carousel-item-shop-title hide-text font-size13 font-weight700">
												{{ item.title }}
											</div>
										</el-tooltip>
										<!-- <el-tooltip :content="item.fRemark ? item.fRemark : item.content"
											placement="top">
											<div class="text-el font-size12 margin-t-10 margin-b-5">
												{{ item.fRemark ? item.fRemark : item.content }}
											</div>
										</el-tooltip> -->
									</div>
									<!-- 								"fillShop"
									<div class="flex-colum-between margin-l-13 font-color-fff" v-if="item.type == 'fillShop'" >
										<div class="box-three-carousel-item-shop-title hide-text font-size18 font-weight700">{{item.title}}</div>
										<div class="font-size13 margin-t-10 margin-b-5">{{item.content}}</div>
									</div> -->
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<!-- <div class="box-three bg-fff padding-13">
				<div class="flex-row-space-between">
					<div class="font-size18 font-weight700">合作品牌</div>
					<div class="flex-row-align-center pointer" @click="toShoplist">
						更多
						<div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20"></div>
					</div>
				</div>
				<div class="box-three-carousel box-row-carousel-two">
					<el-carousel height="208px" :autoplay="false" arrow="always" :loop="false">
						<el-carousel-item v-for="(list, indexList) in hezuoList" :key="indexList">
							<div class="box-three-carousel-item flex-row-wrap">
								<div v-for="(item, index) in list" :key="index"
									class="box-three-carousel-item-brand flex-column-center-center" @click="testUrl">
									<div class="box-three-carousel-item-brand-img-box flex-row-center-center"
										:class="{ 'box-three-carousel-item-brand-img-border': (index + 1) % 4 != 0 }">
										<img class="box-three-carousel-item-brand-img" :src="item.fPath" />
									</div>
									<div class="font-size12 font-color-333">{{ item.fClassPropertieData }}</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div> -->
		</div>
		<!-- 底部广告位 -->
		<div class="box-advert width-1100 bg-F2F2F2 flex-row-center-center margin-b-20">
			<el-carousel height="90px" class="width-1100">
				<el-carousel-item v-for="(item, index) in HomepageBottomAdList" :key="index"><img
						class="main-nav-center-carousel" :src="item.fPath" /></el-carousel-item>
			</el-carousel>
		</div>
		<!-- 	公告通知box -->
		<el-dialog :visible.sync="noticeShow" width="10%" @close="closeNoticeShow" :close-on-click-modal="false"
			:close-on-press-escape="false" class="homeDialog">

			<div class="notice-content">
				<div class="notice-content-item">
					<div v-for="(item, index) in DictData.slice((dictCurrentPage-1)*dictPageSize,dictCurrentPage*dictPageSize)"
						:key="index" class="notice-item-box">
						<div class="close-notice-btn pointer noticeclos" @click="closeNoticeShow"><i
								class="el-icon-circle-close font-color-000 font-size30"></i></div>
						<!-- <img :src="item.fContentPathUrl" alt=""> -->
						<div class=" Notice-title font-weight700 font-size20">{{ item.fTitle }}</div>
						<div class="margin-b-15 overscore">
							<RichText :html="item.fDesc" />
						</div>
						<div class="flex-row-space-between font-size12 " style="display: block;" v-if="dictTotal > 1">
							<div class="text-right">
								<div class="margin-tb-8">聚材通平台</div>
								<div>{{ item.fCreateTime }}</div>
							</div>
						</div>
						<div class="flex-row-space-between font-size12 " style="display: block;" v-else>
							<div class="text-right" style="bottom: -50px;">
								<div class="margin-tb-8">聚材通平台</div>
								<div>{{ item.fCreateTime }}</div>
							</div>
						</div>
					</div>
					<div class="divnum">
						<div v-if="dictTotal > 1" class="divnum">
							<div>{{dictCurrentPage}}/{{dictTotal}}</div>
							<el-pagination class="fy" layout=" prev, next" :current-page.sync="dictCurrentPage"
								:total="dictTotal" :page-size.sync="dictPageSize" prev-text="上一页" next-text="下一页">
							</el-pagination>
						</div>

					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 由于新协议的产生，用户需要重新同意新协议-->
		<el-dialog :visible.sync="newagreement" width="10%" @close="closeNoticeShow" :close-on-click-modal="false"
			:close-on-press-escape="false" class="homeDialog">
			<div class="notice-content">
				<div style="height: 100%;background-color: white;">
					<div class="font-weight700 font-size20" style="color:black;text-align: center;padding-top: 40px;">
						平台协议规则及隐私声明</div>
					<div
						style="text-indent:2em;margin-bottom: 20rpx;font-size: 16px;letter-spacing:5rpx;padding: 10px;">
						尊敬的聚材通会员：根据国家最新颁布的相关法律条款规定，以及本平台交易流程存在部分规则发生调整，为维护您自身权益，现需要您认真阅读并确认以下协议及说明：</div>
					<div>
						<div v-for='(item,index) of agreementList' :key='index'
							style="display: flex;font-size: 16px;margin: 10px;">
							<div style="margin-left:10rpx;color:#2979FF;width: 50%;display: flex;justify-content: space-between;"
								@click="viewAgreement(item.fAgreementHistID)"> 《{{item.fTitle}}》 </div>
							<div style="margin-right:10rpx;color:#2979FF" @click="viewAgreement(item.fAgreementHistID)">
								查看</div>
						</div>
					</div>
					<div
						style="line-height: 20px !important;display: flex;position: absolute;bottom:55px;margin-left: 20px;font-size: 15px;">
						<el-checkbox-group v-model="readAgreementStatus">
							<el-checkbox></el-checkbox>
						</el-checkbox-group>
						<span style="display: inline-block;line-height: 20px;margin-left: 10px;">
							我已阅读以上所有协议
						</span>
						</span>
					</div>
					<div style="width:100%; position: absolute;bottom:20px;display: flex;justify-content: center;">
						<div style="width: 50%;display: flex;justify-content: space-between;">
							<div style="width:150px;line-height: 30px;background-color: #005CC5;color:white;text-align: center;"
								@click="yesAgreee">同意，继续操作</div>
							<div style="width:150px;line-height: 30px;text-align: center;background: #C6CBD1;"
								@click="noAgree">不同意，退出登录</div>
						</div>

					</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="homeShow" style=" left: 54%;margin-left: -20%;top: 50%;margin-top: -23%;"
			:close-on-click-modal="false" :close-on-press-escape="false" class="homeDialog">
			<img style="width: 95%;" src="../assets/imgs/Home/homeShow.png" alt="" class="position-relative" />
			<div class="position-absolute pointer" style="width: 38.5%;height: 5%;bottom: 12.9%;left: 28.5%;"
				@click="instantInvolvement"></div>
			<div class="position-absolute pointer" style="width: 8.4%;height: 6.4%;bottom: 3.5%;left: 43.5%;"
				@click="closeHomeShow"></div>
		</el-dialog>
		<!-- <div class="active_mini pointer" v-if="!homeShow">
			<div><img src="../assets/imgs/Home/homeShowMini.png" alt="" @click="closeHomeShow"
					style="width: 100%;height: 100%;" /></div>
		</div> -->
		<!-- <el-dialog :visible.sync="!homeShow" width="34%" :close-on-click-modal="false" :close-on-press-escape="false" class="homeDialog" @click="closeHomeShow">
			<div class="position-relative">-->

		<!--</div>
		</el-dialog> -->
		<!-- 活动介绍弹窗 -->
		<div v-if='introduceShow== true && isShowNoticesing == true'>
			<el-dialog :visible.sync="introduceShow"
				style="display: flex !important;align-items: center;justify-content: center;"
				:close-on-click-modal="false" :close-on-press-escape="false" class="introduceDialog"
				:class="showIntroduceType==1?'introducePict':'introduceContent'" @close="isShowNoticesingClose">
				<img v-if="showIntroduceType==1" style="width: 100%;" :src="introducePictureUrl" alt=""
					class="position-relative" />
				<div v-if="showIntroduceType==0" class="margin-b-15 overscore">
					<RichTextStyle :html="introduceContent" :styleRule="introduceRule" />
				</div>
			</el-dialog>
		</div>
		<agreementDialog :iContents='lookiContent' ref="lookareDialog"></agreementDialog>

	</div>
</template>
<script>
	import {
		mapActions
	} from 'vuex';
	import {
		mapGetters
	} from 'vuex';
	// import {
	// 	videoPlayer
	// } from "vue-video-player";
	import agreementDialog from '@/components/agreementDialog';
	import SERVER from '../../config/config.json'
	import countDowm from '../components/countDown/countDown.vue';
	import RichTextStyle from '@/components/richtext/RichTextStyle';
	import RichText from '@/components/richtext/RichText'
	import imageBox from '@/components/imageBox/imageBox.vue';
  import CollectCard from '@/components/CollectCard.vue'
  import CollectCardshop from '@/components/CollectCardShop.vue'
	// import debounce from "@/utils/debounce1.js";
	import debounce from 'lodash/debounce'
	export default {
		components: {
			countDowm,
			RichTextStyle,
			RichText,
			imageBox,
			agreementDialog,
      CollectCard,
      CollectCardshop
			// videoPlayer
		},
		data() {
			return {
				leftheight:'',
				readAgreementStatus: false, //协议阅读状态
				agreementList: [], //新协议的数据,
				looktitle: '', //查看协议的标题
				lookfContent: '', //协议内容
				lookAreaDialogName: '', //协议弹窗名
				newagreement: false,
				lookiContent: '',
				autoplay1: true,
				autoplay: true,
				duration: '',
				interval: 3000,
				initialIndex: 0, //轮播默认滑动到第一个
				dialogVisible: false,
				playerVisible: false,
				controls: false,
				muted: false,
				loop: false,
				VideoSrc: '',
				dictTotal: 0,
				dictCurrentPage: 1,
				dictPageSize: 1,
				DictData: [],
				imgurl: require('@/assets/shoplogo.png'),
				homeShow: false,
				isShow: '',
				listimg: 1,
				type: '',
				mainClassifyIcon: [{
						title: '水泥',
						iconName: 'icon-shuinib'
					},
					{
						title: '商砼',
						iconName: 'icon-shuini'
					},
					{
						title: '熟料',
						iconName: 'icon-liaotatouerji'
					},
					{
						title: '矿粉',
						iconName: 'icon-kuangchan'
					},
					{
						title: '干混砂浆',
						iconName: 'icon-shashi'
					}
				],
				// 优惠卷
				couponList: [],
        // 店铺优惠卷
				couponshopList: [],
				// 商品分类
				classifyList: [],
				// 移入主分类
				isMoveMain: false,
				moveMainShow: false,
				// 移入明细分类
				isMoveDetail: false,
				moveDetailsShow: false,
				classifyIndex: null,
				// 分类悬浮框对应的二级三级分类
				transList: {},
				aaa: true,
				// 公告栏
				noticeList: [],
				// 常用功能
				empOftenApplication: [
					[{
							jumpUrl: '/businessBuy',
							title: '企业购',
							iconName: ''
						},
						{
							jumpUrl: '/goldMall',
							title: '金币商城',
							iconName: ''
						},
						{
							jumpUrl: '/Task',
							title: '任务',
							iconName: ''
						},
						{
							jumpUrl: '/SignIn',
							title: '签到',
							iconName: ''
						},
						{
							jumpUrl: '/FunctionPage',
							title: '更多',
							iconName: ''
						}
					]
				],
				testimage: '#icon-shouzhuzhuangtu',
				// 轮播图
				HomepageAdList: [],
				HomepageBottomAdList: [], //底部广告
				AdList: [], //广告
				countDownTime: 0,
        ShopisDistanceStartTime: false,
				isDistanceStartTime: false,
				// 活动板块
				huodongImg: "",
				huodongtitle: "",
				huodongList: [],
				scrollLeft: 0,
				huodongtitleimg: "",
				huodongimgisarea: 0,
				showhuodongtext: "",
				isshowhuodong: false,
				countDownTimeactivity: 0,
				huodongbgpath: "", //活动首页顶部背景图
        // 店铺活动板块
				ShophuodongImg: "",
				Shophuodongtitle: "",
				ShophuodongList: [],
				ShopscrollLeft: 0,
				Shophuodongtitleimg: "",
				Shophuodongimgisarea: 0,
				Shopshowhuodongtext: "",
				Shopisshowhuodong: false,
				ShopcountDownTimeactivity: 0,
				huodongbgpath: "", //活动首页顶部背景图
				// 积分
				integralList: [],
				// 热门店铺
				hotShop: [],
				hSlength: '',
				// 合作品牌
				hezuoList: [],
        // 家装水泥推荐
        homeDecoration: [],
				// 水泥推荐
				hotCementGoodsList: [],
				hotDistributorList: [], //零售水泥推荐
				// 商砼推荐
				hotConcreteGoodsList: [],
				rule: [], //富文本规则
				noticeShow: false, //公告弹层
				maxNoticeCount: 3, //公告每页条数
				Noticepage: 1,
				NoticeDatas: {},
				NoticeData: [
					// {
					// dialogNoticeTitle: "商城临时维护通知",
					// fContent: "<p>尊敬的各位用户：</p><p>我们将于2021.5.19日对商城紧进行临时维护， 我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护 我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护， 我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护 我们将于2021.5.19日对商城紧进行临时维护</p>",
					// fShopName: "聚材通平台",
					// fCreatDate: '2020年11月10日',
					// }
				],

				// 行政区域ID
				cityGovernmenAreaID: -1,
				introduceShow: false, //首页活动弹窗
				showIntroduceType: 0, //首页活动弹窗类型
				introducePictureUrl: '', //首页活动图片路径
				introduceContent: '', //首页活动富文本内容
				introduceRule: [],
				isShowNoticesing: false,
				showactivitytimeobj: "", //积分换购时间数据
				showactivityobj: "", //大型活动时间数据
				showisactivitytimeobj: 0,
				drawAwardPath: '', //抽奖活动
				questionnairePath: '', //问卷调查
				fShopUnitID: '5', //店铺ID
				isGray:false,
				classifyListGoods:[],
        adcode: '',
        //展示背景图片
        showbgimg: false,
        datashopall: [],
        dataall: [],//处理后平台数据

			};
		},
		beforeCreate() {
			if (sessionStorage.getItem('isShowNotices') == null) {
				sessionStorage.setItem('isShowNotices', 1)
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo','getclassifyListValue', 'getareaCut']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created() {
			this.isGray = localStorage.getItem('resultGray')
			this.needD = document.documentElement
			if(this.isGray == 1){
				this.needD.style.cssText =
				'-webkit-filter: grayscale(100%);-moz-filter: grayscale(100%);-ms-filter: grayscale(100%);-o-filter: grayscale(100%);filter: grayscale(100%);filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);filter:gray;'
			}
		},
		watch: {
		    "$route.path":{
		      handler(to, from) {
		        if(this.isGray == 1){
					if(to != from && to!='/home'){
						this.needD.style.cssText = ''
					}else{
						this.needD.style.cssText =
						'-webkit-filter: grayscale(100%);-moz-filter: grayscale(100%);-ms-filter: grayscale(100%);-o-filter: grayscale(100%);filter: grayscale(100%);filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);filter:gray;'
					}
				}
				if(to != "/home"){
					this.introduceShow = false
				}
				if(from == '/login') {
					let fGovCode = ''
					if (sessionStorage.getItem('district')) {
					  let district =  JSON.parse(sessionStorage.getItem('district'))
					  fGovCode = district.code
					} else {
					  let city = JSON.parse(sessionStorage.getItem('city'))
					  fGovCode = city.code
					}
					// this.getGodPro()
					this.getClassifyData()
					this.getHotShop(fGovCode)
					this.getHotCementGoodsList(fGovCode)
          this.homeDecorationGoodsList(fGovCode)
          console.error(11)
				}
		      },
		      deep: true
		    },
        getareaCut: {
          handler(newValue, old) {
			let fGovCode = ''
			if (sessionStorage.getItem('district')) {
			  let district =  JSON.parse(sessionStorage.getItem('district'))
			  fGovCode = district.code
			} else {
			  let city = JSON.parse(sessionStorage.getItem('city'))
			  fGovCode = city.code
			}
            // this.getGodPro()
            this.getClassifyData()
			this.getHotShop(fGovCode)
			this.getHotCementGoodsList(fGovCode)
			this.homeDecorationGoodsList(fGovCode)
			console.error(22)
          },
          deep: true,
        },
		  },
		updated(){
		  	if(localStorage.getItem("loginResult")){
		  		localStorage.removeItem("loginResult")
		  		this.acquireNewContent()
		  	}else{
		  		localStorage.removeItem("loginResult")
		  	}
		},
		created(){
      
		},
		mounted() {
			console.log('最新版本');
			// if (this.$store.getters.getUserInfo == null) {
			// 	console.log('123456');
			// 	this.homeShow = false;
			// }
			// 获取 城市 区域ID
			// this.getLastCity()
			this.getDrawAward(); //获取抽奖活动
			this.getQuestionInvestigation(); //获取问卷调查
			this.getHomepageHeadAdList();
      if (sessionStorage.getItem('district')) {
        let district =  JSON.parse(sessionStorage.getItem('district'))
        this.adcode = district.code
				sessionStorage.setItem('myadcode',this.adcode)
        this.getClassifyData();
        this.getHotCementGoodsList(this.adcode)
        this.homeDecorationGoodsList(this.adcode)
				this.getListByDistributorHot(this.adcode)
				this.getHotShop(this.adcode);
      } else {
        fetch('https://restapi.amap.com/v3/ip?key=' + SERVER.gaodeMapKey,{
						method:'get'
					}).then(async  (response)=>{
						let res  = await  response.json()
            this.adcode = res.adcode
            this.getClassifyData();
            this.acquireaCode()

			      // this.getGodPro()
          })
      }
        
      
			
			// this.DialogNotice();
			// this.getHotShop();
			// this.getListByDistributorHot();
			this.getHotConcreteGoodsList();
			this.queryCouponList();
      this.querycouponshopList()
			this.queryHezuoList();
			this.queryHuodongList();
      this.queryShopHuodongList()
			this.queryIntegralList();
			this.getHomepageAdList();

			this.getHomepageBottomAdList();
			this.getCurretIntroduce();
			// this.DialogUpdate();
			// this.getNoticeList()
			// console.log("获取微信扫码登录的code: " + JSON.stringify(this.$route.query));
			console.log(this.getUserInfo);
			if (sessionStorage.getItem('isShowNotices') == 1) {
				let sum = parseInt(sessionStorage.getItem('isShowNotices')) + 1
				sessionStorage.setItem('isShowNotices', sum)
				this.isShowNoticesing = true
			}
			this.classifyListGoods=this.$store.getters.getclassifyListValue
		},
		// 进入当前界面时执行的生命周期
		activated() {
			// console.log("activatedaaaaaaaa");
			// this.queryCouponList();
			// this.getHotCementGoodsList()
			this.getEmpOftenApplication(); //常用功能
			this.Notice(); //通知公告
			if (this.$store.getters.getUserInfo) {
				this.DialogNotice()
			}
			if (this.$store.getters.getUserInfo) {
				this.acquireNewContent();
			}
			 this.getCurretIntroduce()
			 
		},
		methods: {
			//获取商品属性值
		  getGodPro() {
        let fGovCode = ''
          if (sessionStorage.getItem('district')) {
            let district =  JSON.parse(sessionStorage.getItem('district'))
            fGovCode = district.code
          } else {
            fGovCode = this.adcode
          }
			this.ApiRequestPost('/api/mall/goods/goods-class/get-area-navigations', {
				fGovCode: fGovCode
			}).then(res=>{
				this.classifyListGoods= res.obj.goodsClasses;
				this.$store.dispatch("changeclassifyListData", this.classifyListGoods); 
				// this.$forceUpdate()
			})
				
			},			
			acquireaCode(){
				if(sessionStorage.getItem("ipAdcode") == null){
					fetch('https://restapi.amap.com/v3/ip?key=' + SERVER.gaodeMapKey,{
						method:'get'
					}).then(async  (response)=>{
						let res  = await  response.json()
						if(res.adcode != undefined){
							this.getHotCementGoodsList(res.adcode)
              console.error(33)
              this.homeDecorationGoodsList(res.adcode)
							this.getListByDistributorHot(res.adcode)
							let fGovCode = ''
							if (sessionStorage.getItem('district')) {
							  let district =  JSON.parse(sessionStorage.getItem('district'))
							  fGovCode = district.code
							} else {
							  let city = JSON.parse(sessionStorage.getItem('city'))
							  fGovCode = city.code
							}
							this.getHotShop(fGovCode);
							sessionStorage.setItem('myadcode',res.adcode)
						}else{
							let resadcode = ""
							this.getHotCementGoodsList(resadcode)
              console.error(44)
              this.homeDecorationGoodsList(resadcode)
							this.getListByDistributorHot(resadcode)
							this.getHotShop(resadcode);
							sessionStorage.setItem('myadcode',resadcode)
						}
						sessionStorage.setItem("ipAdcode",res.adcode)
					},(error)=>{
						sessionStorage.setItem("ipAdcode",undefined)
						let resadcode = ""
						this.getHotCementGoodsList(resadcode)
            console.error(55)
            this.homeDecorationGoodsList(resadcode)
						this.getListByDistributorHot(resadcode)
						this.getHotShop(resadcode);
						sessionStorage.setItem('myadcode',resadcode)
					})
				}else{
					if(sessionStorage.getItem("ipAdcode") == undefined){
						let resadcode = ""
						this.getHotCementGoodsList(resadcode)
            console.error(66)
            this.homeDecorationGoodsList(resadcode)
						this.getListByDistributorHot(resadcode)
						this.getHotShop(resadcode);
						sessionStorage.setItem('myadcode',resadcode)
					}else{
						this.getHotCementGoodsList(sessionStorage.getItem("ipAdcode"))
            console.error(77)
            this.homeDecorationGoodsList(sessionStorage.getItem("ipAdcode"))
						this.getListByDistributorHot(sessionStorage.getItem("ipAdcode"))
						let fGovCode = ''
						if (sessionStorage.getItem('district')) {
						  let district =  JSON.parse(sessionStorage.getItem('district'))
						  fGovCode = district.code
						} else {
						  let city = JSON.parse(sessionStorage.getItem('city'))
						  fGovCode = city.code
						}
						this.getHotShop(fGovCode);
						sessionStorage.setItem('myadcode',sessionStorage.getItem("ipAdcode"))
					}
				}
			},
			//获取新协议
			acquireNewContent() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/order-rules-read-record/get-his-isread').then(
					result => {
						console.error('res新协议', result);
						//证明有新协议出现
						if (result.obj.length != 0) {
							this.agreementList = result.obj
							this.newagreement = true
						}
					},
					rej => {}
				);
			},
			//点击阅读上面的协议
			readAgreement(e) {
				console.log(e, 'eeeeeeeeee')
				this.readAgreementStatus = !this.readAgreementStatus
			},
			//点击不同意(1.关闭弹框2.直接退出APP)
			noAgree() {
				// if(this.readAgreementStatus == false){
				// 	this.$refs.uToast.show({
				// 		title: '请勾选阅读协议'
				// 	})
				// 	return
				// }
				this.newagreement = false
				localStorage.clear();
				sessionStorage.clear();
				// 清空vuex登录信息
				this.$store.commit("setUser", null);
				this.$store.commit("setCarCount", 0);
				this.$store.commit("setUserInfo", null);
				this.$store.commit("setMenBList", null);
				this.$store.commit("setMenPList", null);
			},
			//点击同意（1.关闭弹框2.调取阅读记录的接口）
			yesAgreee() {
				if (this.readAgreementStatus == false) {
					this.$message({
						showClose: true,
						message: '请勾选阅读协议',
						type: 'warning'
					});
					return
				}
				this.newagreement = false
				this.recoredList()

			},
			//recoredLIst  这个接口为了记住客户同意了那些协议
			recoredList() {
				//用来存放客户同意的协议id
				let idList = []
				for (let item of this.agreementList) {
					idList.push(item.fAgreementHistID)
				}
				console.log(idList, '查看传过去是否是全部id')
				this.ApiRequestPostNOMess('api/mall/auxiliary/order-rules-read-record/create-his-isread', {
						fAgreementHistIDs: idList
					})
					.then(
						res => {
							console.log('记录客户同意了那些', res);
						},
						rej => {}
					);
			},
			viewAgreement(a) {
				// this.noticeShow = true;
				this.lookiContent = a;
				this.$refs.lookareDialog.show();
			},
			// goPage(url,id,src){
			// 	if(id == '1'){
			// 		url = url.split('/')
			// 		this.$router.push({
			// 			path : '/' + url[url.length - 1]
			// 		});
			// 	}else if(id == '2'){
			// 		let href = src //网站链接
			// 		window.open(href, "_blank");
			// 	}else{

			// 	}
			// },
			handleClose() {
				this.dialogVisible = false
			},
			// 当执行函数后的延迟时间小于设定的时间时，系统会重新计时，并继续等待一段时间。而当延迟时间大于或等于设定时间时，该函数就会被执行
			goHerf:debounce(function (url, id, src, videoPath) {
			console.log('防抖动')
			//内部
			if (id == '1') {
				//视频
				if (videoPath != '') {
					this.dialogVisible = true
					this.VideoSrc = src
				} else {
					//图片 跳转内部页面
					url = url.split('/')
					this.$router.push({
						path: '/' + url[url.length - 1]
					});
				}
				//外部
			} else if (id == '2') {
				if (videoPath != '') {
					let href = src; //网站链接
					window.open(href, "_blank");
				} else {
					let href = url; //网站链接
					window.open(href, "_blank");
				}
			
			} else {
			
			}
			}, 500),
			//方法二，需要引入utils里面的js
			// goHerf:debounce(function (url, id, src, videoPath) {
			// 	console.log(url,id,'000000000000000000000000000000')
			//    //内部
			//    if (id == '1') {
			//    	//视频
			//    	if (videoPath != '') {
			//    		this.dialogVisible = true
			//    		this.VideoSrc = src
			//    	} else {
			//    		//图片 跳转内部页面
			//    		url = url.split('/')
			//    		this.$router.push({
			//    			path: '/' + url[url.length - 1]
			//    		});
			//    	}
			//    	//外部
			//    } else if (id == '2') {
			//    	if (videoPath != '') {
			//    		let href = src; //网站链接
			//    		window.open(href, "_blank");
			//    	} else {
			//    		let href = url; //网站链接
			//    		window.open(href, "_blank");
			//    	}
			   
			//    } else {
			   
			//    }
			//    },500),
			onPlayerLoadeddata(player) {
				console.log(player)
				// 获取资源总时长
				this.duration = player.timeStamp;
				console.log(this.duration)
			},
			//监听媒体是否已到达结尾，播放完
			onPlayerEnded(player) {
				this.autoplay1 = true;
				this.interval = 3000
			},
			// 监听轮播图改变
			onChange(index) {
				// 如果是视频,就等播放完再进行下一个
				// if (this.HomepageAdList[index].type == 1) {
				console.log(index)
				// console.log(this.playerOptions["sources"][0]["src"])
				// this.$refs.videoPlayer[0].player.src(this.playerOptions["sources"][0]["src"])  // 重置进度条,再次轮播时让视频重新播放
				this.interval = this.duration * 1000;
				this.autoplay1 = false;
				// }
			},
			onPlayerPlay(player) {
				console.log(player)
				// player.play();
			},
			//获取抽奖活动
			getDrawAward() {
				this.ApiRequestPostNOMess('/api/mall/ebactivity/luck-draw-award-pool/get-self-support-currettime').then(
					res => {
						console.log('获取抽奖活动drawAwardPath', res);
						this.drawAwardPath = res.obj[0].fWebFullPath
						// this.fShopUnitID
					},
					rej => {}
				);
			},
			//获取问卷调查
			getQuestionInvestigation() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/questionnaire/get-curret-questionnaire').then(res => {
					console.log('获取问卷调查questionnairePath', res);
					if (res.obj) {
						this.questionnairePath = res.obj.fWebPath
						console.log('问卷调查this.url', url);
					}
				}, error => {});
			},
			//跳转抽奖页面
			toDrawAward() {
				if (this.$store.getters.getUserInfo == null) {
					return this.$store.dispatch('setShowLogin', true);
				}
				const status = this.$store.getters.getUserInfo.fVerifiedStatus;
				if (!status) {
					return this.$store.dispatch('setShowAttestation', true);
				}
				console.log('跳转抽奖页面');
				this.$router.push({
					name: 'luckMainPlatform',
					query: {
						// shopId: this.fShopUnitID,
						shopId: '-1'
					}
				});
			},
			//跳转问卷调查页面
			toQuestionnaire() {
				if (this.$store.getters.getUserInfo == null) {
					return this.$store.dispatch('setShowLogin', true);
				}
				const status = this.$store.getters.getUserInfo.fVerifiedStatus;
				if (!status) {
					return this.$store.dispatch('setShowAttestation', true);
				}
				console.log('跳转问卷调查页面');
				this.$router.push({
					name: 'QuestionnaireInvestigation',
				});
			},
			isShowNoticesingClose() {
				this.isShowNoticesing = false
			},
			//首次登录弹窗立即参与事件
			instantInvolvement() {
				if (this.$store.getters.getUserInfo == null) {
					return this.$store.dispatch('setShowLogin', true);
				} else {
					this.closeHomeShow();
					this.$message('登录金币领取成功');
				}
			},
			//首次登录弹窗关闭事件
			closeHomeShow() {
				this.homeShow = !this.homeShow;
			},
			//获取首页上面广告
			getHomepageHeadAdList() {
				this.ApiRequestPost('/api/mall/ebbase/ebadvertisement/get-homepage-head-ad-list', {
					fAppTypeID: this.fAppTypeID
				}).then(
					result => {
						console.log('获取首页上面广告', result);
					},
					rej => {}
				);
			},
			//获取首页底部广告
			getHomepageBottomAdList() {
				this.ApiRequestPost('/api/mall/ebbase/ebadvertisement/get-homepage-bottom-ad-list', {
					fAppTypeID: this.fAppTypeID
				}).then(
					result => {
						console.log('获取首页底部广告', result);
						this.HomepageBottomAdList = result.obj;
					},
					rej => {}
				);
			},
			getRule(item) {
				console.log(item);
				if (item.useRules) {
					if (item.useRules.length > 0) {
						return item.useRules[0].fUseDescribe;
					}
				}
				return '';
			},
			timeDifference(startTime, endTime) {
				var start1 = startTime.split(':');
				var startAll = parseInt(start1[0] * 60) + parseInt(start1[1]);
				var end1 = endTime.split(':');
				var endAll = parseInt(end1[0] * 60) + parseInt(end1[1]);
				console.log('时间差===' + (endAll - startAll));
			},
			//获取通知公告
			Notice() {
				this.ApiRequestPost('/api/mall/ebbase/announcement-notice-record/get-more-notice-gg-page',{
					filter:"",
					maxResultCount: 5,
					skipCount:0,
					sorting:""
				}).then(
					result => {
						// console.log('获取通知公告列表信息', result.obj)
						for (let item of result.obj.items) {
							// item.fAnnouncementType = item.fAnnouncementType.substr(0, 2);
							item.fCreateTime = this.getDate(item.fCreateTime).substr(5);
							
						   console.log(item.fCreateTime)
						}
						this.DialogNotice()

						this.noticeList = this.makeListForNum(result.obj.items, 5);
						console.log('广告整理后的数据', this.noticeList)
					},
					rej => {}
				);
			},
			//获取弹框公告
			DialogNotice() {
				// if(this.getUserInfo!=null){
				// if(this.getUserInfo.fVerifiedStatus){
				this.ApiRequestPostNOMess('api/mall/ebbase/announcement-notice-record/get-must-read-homepage-notice').then(
					result => {
						// return
						console.log(result.obj)
						this.NoticeData = result.obj;
						this.DictData = result.obj;
						this.dictTotal = this.NoticeData.length;
						if (this.NoticeData.length > 0 && sessionStorage.getItem(this.getUserInfo.adminPhone) ==
							null) {
							this.noticeShow = true;
							sessionStorage.setItem(this.getUserInfo.adminPhone, '1');
							// this.NoticeData.fRecordID = result.obj[0].fRecordID;
						}
						for (let item of result.obj) {
							item.fCreateTime = this.getDate(item.fCreateTime);
						}




					},
					rej => {

					}
				);
				// }
				// }

			},
			...mapActions(['setUser']),
			// 获取 城市 区域ID
			getLastCity() {
				this.ApiRequestPostNOMess('api/mall/sys/account/get-last-city', {})
					.then(res => {
						this.cityGovernmenAreaID = res.city;
					})
					.catch(err => {});
			},
			// 跳转常用功能设置
			jumpSet() {
				this.$router.push('/FunctionPage');
			},
			//领券点击事件
			receiveClick() {
				if (this.$store.getters.getUserInfo == null) {
					return this.$store.dispatch('setShowLogin', true);
				}
				const status = this.$store.getters.getUserInfo.fVerifiedStatus;
				if (!status) {
					return this.$store.dispatch('setShowAttestation', true);
				}
				this.$router.push('/CouponCollection');
			},
    //领券点击事件
    receiveSHopClick () {
      if (this.$store.getters.getUserInfo == null) {
        return this.$store.dispatch('setShowLogin', true);
      }
      const status = this.$store.getters.getUserInfo.fVerifiedStatus;
      if (!status) {
        return this.$store.dispatch('setShowAttestation', true);
      }
      // this.$router.push('/CouponCollection');
      this.$router.push({
        name: 'CouponCollectionShop',
        query: { id: this.fShopUnitID }
      });
    },
			getIconName(title) {
				let iconName = '';
				for (let item of this.mainClassifyIcon) {
					if (item.title == title) {
						iconName = item.iconName;
						break;
					}
				}
				return iconName;
			},
			// 获取 广告轮播图
			getHomepageAdList() {
				this.ApiRequestPostNOMess('api/mall/ebbase/ebadvertisement/get-homepage-ad-list', {
						fAppTypeID: this.fAppTypeID
					})
					.then(res => {
						if (res.obj.length > 0) {
							res.obj.map(item => {
								// alert(item.fPath.search('mp4'))
								if (item.fVideoPath.substr(item.fVideoPath.length - 1, 2) == '/') {
									item.fVideoPath = ''
									// item.fPath = 'https://img2.baidu.com/it/u=3176497550,2852925249&fm=253&fmt=auto&app=138&f=JPEG?w=680&h=310'
								}
							})
						}
						this.HomepageAdList = res.obj;
					})
					.catch(err => {});
			},
			getClassifyData() {
        let fGovCode = ''
          if (sessionStorage.getItem('district')) {
            let district =  JSON.parse(sessionStorage.getItem('district'))
            fGovCode = district.code
          } else {
            fGovCode = this.adcode
          }
				this.ApiRequestPost('/api/mall/goods/goods-class/get-area-navigations', {
          fGovCode: fGovCode
        }).then(res => {
					console.log(res, '左侧分类数据');
					res.obj.goodsClasses.forEach(item => {
						item.checked = false;
					});
					this.classifyList = res.obj.goodsClasses;
					this.leftheight =  (395/this.classifyList.length) +"px"
				});
			},
			testUrl(item) {
				console.log('拿到传输数据', item)
				this.$router.push({
					path: '/Goods',
					
					// query: {
					// 	item: JSON.stringify(item)
					// }
				});
               this.$store.commit("changehomeIsGoodsData", item); 
				// if (!this.$store.getters.getUser) {
				// 	this.$store.dispatch("setShowLogin", true);
				// 	return;
				// } else {

				// 	let status = this.$store.getters.getUser.fVerifiedStatus;
				// 	if (status == false) {
				// 		this.$store.dispatch("setShowAttestation", true);
				// 		return;
				// 	}
				// }
			},
			// 跳转个人中心
			jumpPersonal() {
				this.$router.push({
					name: 'PersonalContent'
				});
			},

			// 跳转登录
			jumpLogin() {
				this.$router.push({
					name: 'login'
				});
			},
			// 跳转限时活动
			LimitedTimeActivities() {
				this.$router.push({
					name: 'LimitedTimeActivities',
					query: {
						shopID: -1
					}
				});
			},
			// 跳转活动
			LimitedActivities() {
				this.$router.push({
					name: 'LimitedActivities'
				});
			},
      LimitedshopActivities () {
      this.$router.push({
        name: 'LimitedShopActivities',
        query: { id: this.fShopUnitID }
      });
    },
			//跳转公告
			NoticeMore() {
				this.$router.push({
					name: 'News',
					params: {
						shownotice: "home"
					}
				});
			},
			register() {
				this.$router.push({
					name: 'register'
				});
			},
			// 退出登录
			LogOut() {
				this.visible = false;
				localStorage.clear();
				sessionStorage.clear();
				this.$router.push('./');
				// 清空vuex登录信息
				this.$store.commit('setUser', null);
				this.$store.commit('setUserInfo', null);
				this.$store.commit('setCarCount', 0);
				this.$store.commit('setMenBList', null);
				this.$store.commit('setMenPList', null);
			},
			// 获取常用功能
			getEmpOftenApplication() {
				this.empOftenApplication = [];
				this.ApiRequestPostNOMess('api/mall/sys/emp-often-application/get-list', {})
					.then(res => {
						if (res.obj.items.length > 0) {
							res.obj.items = res.obj.items;
							res.obj.items.forEach(item => {
								item.title = item.fIconClassName;
								// item.iconName =
								// item.imgUrl =
								// item.jumpUrl =
							});
							this.empOftenApplication = this.makeListForNum(res.obj.items, 9);
							console.log('this.empOftenApplication', this.empOftenApplication);
						} else {
							this.empOftenApplication.push([{
									fApplicationName: '/businessBuy',
									title: '企业购',
									fIconClass: 'icon-qiyegou'
								},
								{
									fApplicationName: '/goldMall',
									title: '金币商城',
									fIconClass: 'icon-shangcheng'
								},
								{
									fApplicationName: '/Task',
									title: '任务',
									fIconClass: 'icon-renwu'
								},
								{
									fApplicationName: '/SignIn',
									title: '签到',
									fIconClass: 'icon-qiandao'
								},
								{
									title: '更多',
									fIconClass: '',
									jumpUrl: '/FunctionPage'
								}
							]);
						}
					})
					.catch(err => {});
			},
			// 常用功能跳转
			modularJumpPage(url) {
				// console.log('url', url);
				if (url == 'businessBuy' && this.getUserInfo.fShowPrice == 0) {
					this.$message('当前用户不能使用此功能');
					return;
				}
				this.$router.push(url);
			},
			// 跳转去活动详情页
			goactivitydetail() {
				this.$router.push("");
			},
			// 获取活动数据
			queryHuodongList() {
				// 请求:
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-activity-goods-list', {
						fGovernmenAreaID: this.cityGovernmenAreaID,
						fAppTypeID: 2,
						skipCount: 0,
						maxResultCount: 15,
						filter: ""
					}).then(res => {
						let obj = res.obj;
						if (obj.couponInfo == null || obj.goods == null || obj.goodsClass == null) {
							this.isshowhuodong = false
							return;
						} else {
							this.huodongList = this.makeListForNum(obj.goods, 5);
							console.log(this.huodongList, '活动的列表数据')
							this.huodongImg = obj.couponInfo.fIconPath; //活动商品左上角图
							this.huodongtitle = obj.couponInfo.fEventName;
							this.huodongtitleimg = obj.couponInfo.fTitlePicPath; //顶部图片
							this.huodongimgisarea = obj.couponInfo.fDispArea; //活动图在左边还是右边
							this.huodongbgpath = obj.couponInfo.fBackGroundPicPath;
							if (this.showactivitytimeobj != "") {
								this.isshowhuodong = true
								// 大型活动
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fRemindTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fBeginTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fBeginTime).getTime() - new Date()
										.getTime())
									this.showhuodongtext = "优惠券距开始:"
									this.countDownTimeactivity = (new Date(res.obj.couponInfo.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fBeginTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fEndTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fEndTime).getTime() - new Date().getTime())
									this.showhuodongtext = "优惠券距结束:"
									this.countDownTimeactivity = (new Date(res.obj.couponInfo.fEndTime).getTime() -
										new Date()
										.getTime())
								}
								// 积分换购
								if (new Date().getTime() >= new Date(this.showactivitytimeobj.fRemindTime).getTime() &&
									new Date()
									.getTime() <
									new Date(this.showactivitytimeobj.fBeginTime).getTime()) {
									console.log(new Date(this.showactivitytimeobj.fBeginTime).getTime() - new Date()
										.getTime())
									this.isDistanceStartTime = true;
									this.countDownTime = (new Date(this.showactivitytimeobj.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(this.showactivitytimeobj.fBeginTime).getTime() &&
									new Date()
									.getTime() <
									new Date(this.showactivitytimeobj.fEndTime).getTime()) {
									console.log(new Date(this.showactivitytimeobj.fEndTime).getTime() - new Date()
										.getTime())
									this.isDistanceStartTime = false;
									this.countDownTime = (new Date(this.showactivitytimeobj.fEndTime).getTime() -
										new Date().getTime())
								}

							} else {
								this.isshowhuodong = true
								this.showactivityobj = res.obj.couponInfo
								// 大型活动
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fRemindTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fBeginTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fBeginTime).getTime() - new Date()
										.getTime())
									this.showhuodongtext = "优惠券距开始:"
									this.countDownTimeactivity = (new Date(res.obj.couponInfo.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fBeginTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fEndTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fEndTime).getTime() - new Date().getTime())
									this.showhuodongtext = "优惠券距结束:"
									this.countDownTimeactivity = (new Date(res.obj.couponInfo.fEndTime).getTime() -
										new Date()
										.getTime())
								}
							}
							// let beforeshow = new Date(res.obj.couponInfo.fRemindTime).getTime(); //活动开始展示时间
							// let start = new Date(res.obj.couponInfo.fBeginTime).getTime(); //活动开始时间
							// let end = new Date(res.obj.couponInfo.fEndTime).getTime(); //活动结束时间
							// let now = new Date().getTime();
						}
					})
					.catch(err => {});
			},
      			// 获取活动数据
			queryShopHuodongList() {
				// 请求:
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-activity-goods-list-self-owned-shop', {
						fGovernmenAreaID: this.cityGovernmenAreaID,
						fAppTypeID: 2,
						skipCount: 0,
						maxResultCount: 15,
						filter: ""
					}).then(res => {
						let obj = res.obj;
						if (obj.couponInfo == null || obj.goods == null || obj.goodsClass == null) {
							this.isshowhuodong = false
							return;
						} else {
							this.ShophuodongList = this.makeListForNum(obj.goods, 5);
							console.log(this.huodongList, '活动的列表数据')
							this.ShophuodongImg = obj.couponInfo.fIconPath; //活动商品左上角图
							this.Shophuodongtitle = obj.couponInfo.fEventName;
							this.Shophuodongtitleimg = obj.couponInfo.fTitlePicPath; //顶部图片
							this.Shophuodongimgisarea = obj.couponInfo.fDispArea; //活动图在左边还是右边
							this.Shophuodongbgpath = obj.couponInfo.fBackGroundPicPath;
							if (this.showactivitytimeobj != "") {
								this.Shopisshowhuodong = true
								// 大型活动
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fRemindTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fBeginTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fBeginTime).getTime() - new Date()
										.getTime())
									this.Shopshowhuodongtext = "优惠券距开始:"
									this.ShopcountDownTimeactivity = (new Date(res.obj.couponInfo.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fBeginTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fEndTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fEndTime).getTime() - new Date().getTime())
									this.showhuodongtext = "优惠券距结束:"
									this.ShopcountDownTimeactivity = (new Date(res.obj.couponInfo.fEndTime).getTime() -
										new Date()
										.getTime())
								}
								// 积分换购
								if (new Date().getTime() >= new Date(this.Shopshowactivitytimeobj.fRemindTime).getTime() &&
									new Date()
									.getTime() <
									new Date(this.Shopshowactivitytimeobj.fBeginTime).getTime()) {
									console.log(new Date(this.Shopshowactivitytimeobj.fBeginTime).getTime() - new Date()
										.getTime())
									this.ShopisDistanceStartTime = true;
									this.ShopcountDownTime = (new Date(this.showactivitytimeobj.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(this.Shopshowactivitytimeobj.fBeginTime).getTime() &&
									new Date()
									.getTime() <
									new Date(this.Shopshowactivitytimeobj.fEndTime).getTime()) {
									console.log(new Date(this.Shopshowactivitytimeobj.fEndTime).getTime() - new Date()
										.getTime())
									this.ShopisDistanceStartTime = false;
									this.ShopcountDownTime = (new Date(this.Shopshowactivitytimeobj.fEndTime).getTime() -
										new Date().getTime())
								}

							} else {
								this.Shopisshowhuodong = true
								this.Shopshowactivityobj = res.obj.couponInfo
								// 大型活动
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fRemindTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fBeginTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fBeginTime).getTime() - new Date()
										.getTime())
									this.Shopshowhuodongtext = "优惠券距开始:"
									this.ShopcountDownTimeactivity = (new Date(res.obj.couponInfo.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(res.obj.couponInfo.fBeginTime).getTime() &&
									new Date()
									.getTime() < new Date(
										res.obj.couponInfo.fEndTime).getTime()) {
									console.log(new Date(res.obj.couponInfo.fEndTime).getTime() - new Date().getTime())
									this.Shopshowhuodongtext = "优惠券距结束:"
									this.ShopcountDownTimeactivity = (new Date(res.obj.couponInfo.fEndTime).getTime() -
										new Date()
										.getTime())
								}
							}
						}
					})
					.catch(err => {});
			},
			//导航栏进度条
			// getScoll(){
			// 	// 获取高度
			// 	var getHeight = docment.getElementByClass('main-nav-left-item-height')
			// 	var attr= getHeight.style.height
			// 	//获取滚动条显示隐藏
			// 	var getScll = docment.getElementByClass('main-nav-left-inner-box')
			// 	var getDis = getScll.style.overflow + '-' +  y

			// 	if(attr < 80 + 'px'){
			// 		getDis.style.overflow-y = hidden
			// 	}else {
			// 		getDis.style.overflow-y = scroll
			// 	}
			// },
			// 获取 积分兑换 是否显示 结束时间
			queryIntegralList() {
				this.ApiRequestPostNOMess('/api/mall/ebactivity/point-swap-goods-rule-bill/get-cuurent-platform', {}).then(
					result => {
						console.log(result, 'result8888888');
						this.showisactivitytimeobj = result.obj.isShow;
						console.log(this.isShow, ' this.isShow');
						if (result.obj.isShow == 1) {
							if (this.showactivityobj != "") {
								this.isshowhuodong = true
								this.isShow = this.showisactivitytimeobj

								// 大型活动
								if (new Date().getTime() >= new Date(this.showactivityobj.fRemindTime).getTime() &&
									new Date()
									.getTime() < new Date(
										this.showactivityobj.fBeginTime).getTime()) {
									console.log(new Date(this.showactivityobj.fBeginTime).getTime() - new Date()
										.getTime())
									this.showhuodongtext = "优惠券距开始:"
									this.countDownTimeactivity = (new Date(this.showactivityobj.fBeginTime).getTime() -
										new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(this.showactivityobj.fBeginTime).getTime() &&
									new Date()
									.getTime() < new Date(
										this.showactivityobj.fEndTime).getTime()) {
									console.log(new Date(this.showactivityobj.fEndTime).getTime() - new Date()
										.getTime())
									this.showhuodongtext = "优惠券距结束:"
									this.countDownTimeactivity = (new Date(this.showactivityobj.fEndTime).getTime() -
										new Date()
										.getTime())
								}
								// 积分换购
								if (new Date().getTime() >= new Date(result.obj.dto.fRemindTime).getTime() &&
									new Date().getTime() <
									new Date(
										result.obj.dto.fBeginTime).getTime()) {
									console.log(new Date(result.obj.dto.fBeginTime).getTime() - new Date().getTime())
									this.isDistanceStartTime = true;
									this.countDownTime = (new Date(result.obj.dto.fBeginTime).getTime() - new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(result.obj.dto.fBeginTime).getTime() && new Date()
									.getTime() <
									new Date(
										result.obj.dto.fEndTime).getTime()) {
									console.log(new Date(result.obj.dto.fEndTime).getTime() - new Date().getTime())
									this.isDistanceStartTime = false;
									this.countDownTime = (new Date(result.obj.dto.fEndTime).getTime() - new Date()
										.getTime())
								}

							} else {
								this.isShow = this.showisactivitytimeobj
								this.showactivitytimeobj = result.obj.dto
								// 积分换购
								if (new Date().getTime() >= new Date(result.obj.dto.fRemindTime).getTime() &&
									new Date().getTime() <
									new Date(
										result.obj.dto.fBeginTime).getTime()) {
									this.isDistanceStartTime = true;
									this.countDownTime = (new Date(result.obj.dto.fBeginTime).getTime() - new Date()
										.getTime())
								}
								if (new Date().getTime() >= new Date(result.obj.dto.fBeginTime).getTime() && new Date()
									.getTime() <
									new Date(
										result.obj.dto.fEndTime).getTime()) {
									console.log(new Date(result.obj.dto.fEndTime).getTime() - new Date().getTime())
									this.isDistanceStartTime = false;
									this.countDownTime = (new Date(result.obj.dto.fEndTime).getTime() - new Date()
										.getTime())
								}
							}


							// if (new Date(result.obj.dto.fBeginTime) < new Date()) {
							// 	this.countDownTime = new Date(result.obj.dto.fEndTime) - new Date();
							// 	this.isDistanceStartTime = false;
							// } else {
							// 	this.countDownTime = new Date(result.obj.dto.fBeginTime) - new Date();
							// 	this.isDistanceStartTime = true;
							// }


							this.ApiRequestPost('/api/mall/ebactivity/point-swap-goods-price/get-point-swap-goods', {})
								.then(res => {
									console.log(res, 'resssssssssswwwwwwwww');
									this.integralList = res.obj.items;
                  let log = this.integralList.length
									this.integralList = this.makeListForNum(this.integralList, 6);
                  this.showbgimg = log                  
								});
						} else {
							this.integralList = []
						}
					},
					error => {
						//   alert(JSON.stringify(error));
					}
				);
			},
			// queryIntegralList() {
			// 	const copyObj = {
			// 		tips: '限时',
			// 		img: require('@/assets/imgs/Home/cementBag.png'),
			// 		title: '天山牌普硅42.5叶城天山袋啦啦啦啦',
			// 		integral: '1068'
			// 	}
			// 	let list = []
			// 	for (let i = 0; i < 18; i++) {
			// 		list.push(copyObj)
			// 	}
			// 	this.integralList = JSON.parse(JSON.stringify(this.makeListForNum(list, 6)));
			// },
			// 获取合作品牌数据
			queryHezuoList() {
				let list = [];

				this.ApiRequestPost('api/mall/goods/goods-class-properties-data/get-cooperative-brand')
					.then(res => {
						console.log(res, '合作品牌');
						list = res.obj;
						this.hezuoList = JSON.parse(JSON.stringify(this.makeListForNum(list, 12)));
					})
					.catch(err => {});
			},
			// 获取优惠券数据 {
			queryCouponList() {
				this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/get-homepage-coupon-info')
					.then(res => {
            // alert(1)
						console.log(res, 111111111);
						this.couponList =  JSON.parse(JSON.stringify(res.obj.slice(0, 4)));
            let data = []
            let data1 = []
            if(this.couponList.length > 0){
            this.couponList.forEach((item,index)=>{
              console.log(index)
              if(index < 2){
                data.push(item)
              } else {
                data1.push(item)
              }
            })
           if(data1.length == 0){
            this.dataall = [data]
           }
           else{
            this.dataall = [data,data1]
           }
          }
						this.fStatus = res.obj[0].fStatus;
					})
					.catch(err => {});
			},
      //获取店铺优惠券数据
      querycouponshopList() {
        this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/get-page-coupon-info-self-owned-shop')
					.then(res => {
						console.log(res, 111111111);
						this.couponshopList = res.obj.slice(0, 4);
            let data = []
            let data1 = []
            if(this.couponshopList.length > 0){
            this.couponshopList.forEach((item,index)=>{
              console.log(index)
              if(index < 2){
                data.push(item)
              } else {
                data1.push(item)
              }
            })
           if(data1.length == 0){
            this.datashopall = [data]
           }
           else{
            this.datashopall = [data,data1]
           }
          }
            // datashopall
					})
					.catch(err => {});
			},
			//水泥推荐更多跳转商品列表  合作品牌右侧更多跳转商品列表
			toGoods(e) {
				console.log(e)
				if (e) {
					this.$router.push({
						path: '/Goods',
						query: {
							class: e,
							item: "",
						}
					});
				} else {
					this.$router.push({
						path: '/Goods',
						item: "",
					});
				}


			},
			toShoplist() {
				this.$router.push({
					path: '/ShopList'
				});
			},
			// 跳转商品详情
			jumpGoodDetails(item) {
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: item.goods.fGoodsID
					}
				});
			},
			// 鼠标 移入 主分类区域
			moveMainInFun(index) {
				console.log('index', index)
				if (this.classifyList[index].classifyIndex == index) {
					this.classifyList[index].checked = true;
				} else {
					this.classifyList[index].checked = false;
				}
        console.log(this.classifyList[index].checked)
				this.moveMainShow = true;
				this.isMoveMain = true;
				this.moveDetailsShow = true;
				this.transList.index = index
				this.transList.item = JSON.parse(JSON.stringify(this.classifyList[index].goodsClassProperties));
				console.log(this.transList)

			},
			// 鼠标 移出 主分类区域
			moveMainLeaveFun(index) {
				this.isMoveMain = false;
				let _this = this;
				// isMoveDetail 是否进入明细分类
				if (_this.isMoveDetail == false && _this.moveDetailsShow == true) {
					_this.moveDetailsShow = false;
					_this.moveMainShow = false;
					_this.classifyList.forEach((item, classifyIndex) => {
						item.checked = false;
					});
				}
			},
			// 鼠标 移入 明细分类区域
			moveDetailInFun() {
				this.classifyList.forEach(item=> {
					item.checked = false
				})
				this.classifyList[this.transList.index].checked = true
				this.isMoveDetail = true;
				this.moveDetailsShow = true;
			},
			// 鼠标 移出 明细分类区域
			moveDetailLeaveFun() {
				let _this = this;
				this.isMoveDetail = false;
				setTimeout(function() {
					// isMoveMain 是否进入分类
					if (_this.isMoveMain == false && _this.moveDetailsShow == true) {
						_this.moveDetailsShow = false;
						_this.moveMainShow = false;
						_this.classifyList.forEach((item, classifyIndex) => {
							item.checked = false;
						});
					}
				}, 300);
			},
			// 倒计时结束事件
			countDownEnd(state, index) {
				// debugger
				// state = false , index
				setTimeout(this.queryIntegralList(), 500)
			},
			countDownEndactivity() {
				setTimeout(this.queryHuodongList(), 500)
			},
      ShopcountDownEndactivity() {
				setTimeout(this.queryShopHuodongList(), 500)
			},

			//跳转积分换购商品详情
			toIntegralDetails(item) {
				this.$router.push({
					path: '/Goods/IntegralGoodsDetails',
					query: {
						fGoodsID: item.fGoods.fGoodsID
					}
				});
			},

			// 获取推荐店铺数据
			getHotShop(val) {
				// hotShop
				let obj = {
					type: 'fillShop',
					img: require('@/assets/imgs/Home/home_default_shop.png'),
					title: '虚位以待',
					content: '招商！期待您的加入'
				};
				this.ApiRequestPostNOMess('api/mall/ebshop/baseinfo/get-hot-list-new', {
					    version: "",
					     adcode: val,
					     city: "",
					     flng: 0,
					     flat: 0,
				}).then(res => {
						res.obj.forEach(item => {
							item.type = 'userShop';
							item.img = item.fShopLogoPath;
							item.content = item.fRemark;
							item.title = item.fShopName;
						});
						let arr = []
						if (res.obj.length > 3) {
							for (var i = 0; i < res.obj.length; i++) {
								// if (i < 3) {
								arr.push(res.obj[i])
								// }
							}
						} else {
							arr = res.obj
						}
						if(arr.length ==8){
							// arr.push(obj);
							imgurl: require('@/assets/shoplogo.png')
							let list = this.makeListForNum(arr, 7);
							list.map((item,index)=>{
								list[index].push(obj)
							})
							this.hotShop = list;
							console.log('this.hotShop', this.hotShop);
						}else{
							let list = this.makeListForNum(arr, 7);
							// list[list.length - 1].push(obj);
							list.map((item,index)=>{
								list[index].push(obj)
							})
							imgurl: require('@/assets/shoplogo.png')
							(this.hotShop = list);
						}
						
						console.log("展示的店铺数量", list[list.length - 1])
						// for (var i = 0; i < arr.length; i++) {
						// list[list.length - 1].push(obj);
						// }

						// for (let item of list) {
						// imgurl: require('@/assets/shoplogo.png'),
							// }
							// (this.hotShop = list);
						// let hslength = []
						// this.hotShop.map(item=>{
						// 	item.map(cell=>{
						// 		if(cell.type == 'userShop'){
						// 			hslength.push(cell)
						// 		}
						// 	})
						// })
						//目前有多少家店铺
						// this.hSlength = hslength.length;
						//不够数量补全
						// this.hotShop.map(item=>{
						// 	if(item.length < 10){
						// 		let num = item.length
						// 		for(let i = 0; i < 10 - num; i++){
						// 			item.push({
						// 				type : 'userShop',
						// 				isImage : 'false'
						// 			})
						// 		}
						// 	}
						// })

						console.log('this.hotShop', this.hotShop);
					})
					.catch(err => {});
			},
			// 重组列表  返回以每 num 个为一组的数据  如果其他页面用的多可写入public.js
			makeListForNum(list, num) {
				console.log(list);
				console.log('获取初始数据')
				let returnLIst = [];
				for (var i = 0; i < list.length; i += num) {
					returnLIst.push(list.slice(i, i + num));
				}
				console.log(returnLIst);
				console.log('获取整理后的数组。')
				return returnLIst;
			},
      // 获取家装水泥推荐
      homeDecorationGoodsList(val) {
        this.homeDecoration = []
        if (val.substr(0,2) == '65') {
          this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-hot-cement-goods-list-new24-pin-pai', {
						  "version": "",
						  "adcode":val,
						  "city": "",
						  "flng": 0,
						  "flat": 0,
					})
					.then(res => {
						// console.log('获取推荐水泥列表=====================================',res.obj.items)

						this.homeDecoration = res.obj.items.slice(0, 5);
						console.log(this.homeDecoration, '家装水泥')
					})
					.catch(err => {});
        }
        
      },
			// 获取热门水泥
			getHotCementGoodsList(val) {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-hot-cement-goods-list-new24', {
						  "version": "",
						  "adcode":val,
						  "city": "",
						  "flng": 0,
						  "flat": 0,
					})
					.then(res => {
						// console.log('获取推荐水泥列表=====================================',res.obj.items)

						this.hotCementGoodsList = res.obj.items.slice(0, 15);
						console.log(this.hotCementGoodsList, '看下这里的水泥 ')
					})
					.catch(err => {});
			},
			// 获取零售水泥推荐为其他建材推荐
			getListByDistributorHot(val) {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-by-distributor-hot-new', {
					     version: "",
					     adcode: val,
					     city: "",
					     flng: 0,
					     flat: 0,
						fAppTypeID: 2
					})
					.then(res => {
						// console.log('看下这里的零售水泥=====================================',res.obj)

						this.hotDistributorList = res.obj.slice(0, 15);
						console.log(this.hotDistributorList, '看下这里的零售水泥 ')
					})
					.catch(err => {});
			},
			// 获取商砼推荐
			getHotConcreteGoodsList() {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-hot-concrete-goods-list', {
						id: this.cityGovernmenAreaID
					})
					.then(res => {
						this.hotConcreteGoodsList = res.obj.slice(0, 5);
					})
					.catch(err => {});
			},
			// 公告数据
			// sessionStorage.setItem("setUserInfor", "")
			// getNoticeList() {
			// 	if(this.NoticeData.length  ==  0 ){
			// 		this.noticeShow = false;
			// 	}else if(this.NoticeData.length > 0 && sessionStorage.getItem('notice')==null){
			// 		this.noticeShow = true;
			// 		sessionStorage.setItem('notice','1')
			// 	}
			// },
			//公告分页点击事件
			handleCurrentChange(val) {
				this.Noticepage = val;
				// this.getNoticeList();
			},
			//公告关闭按钮点击事件
			closeNoticeShow() {
				this.noticeShow = false;
			},
			//公告下一页事件
			nextBtn() {
				this.DialogUpdate();
				this.$set('this.NoticeData', 'this.Noticepage', 'this.NoticeDatas');
				this.Noticepage = this.Noticepage + 1;
				// console.log(this.Noticepage,"this.Noticepage")
				// this.DialogUpdate()
			},
			//公告上一页事件
			prvtBtn() {
				this.Noticepage = this.Noticepage - 1;
				this.getdefault();
			},
			// DialogUpdate() {
			// 	if(this.getUserInfo != null){
			// 		if(this.getUserInfo.fVerifiedStatus){
			// 			this.ApiRequestPost('api/mall/ebbase/announcement-notice-record/get-must-read-homepage-notice', {

			// 			}).then(
			// 				result => {
			// 					for (let item of result.obj) {
			// 						item.fDelayedSendingTime = this.getDate(item.fDelayedSendingTime);
			// 					}
			// 					this.NoticeData = result.obj;
			// 					if (this.NoticeData.length > 0 &&(sessionStorage.getItem('notice') == null||sessionStorage.getItem('notice') == '')) {
			// 						this.noticeShow = true;
			// 						sessionStorage.setItem('notice', '1');
			// 					}
			// 				},
			// 				rej => {}
			// 			);
			// 		}
			// 	}

			// },
			//公告假数据测试分页
			getdefault() {
				// if (this.Noticepage == 1) {
				// 	this.NoticeData = [{ //公告数据
				// 		dialogNoticeTitle: "商城临时维护通知",
				// 		fContent: "<p>尊敬的各位用户：</p><p>我们将于2021.5.19日对商城紧进行临时维护， 我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护 我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护， 我们将于2021.5.19日对商城紧进行临时维护,我们将于2021.5.19日对商城紧进行临时维护 我们将于2021.5.19日对商城紧进行临时维护</p>",
				// 		fShopName: "聚材通平台",
				// 		fCreatDate: '2020年11月10日'
				// 	}]
				// 	this.$forceUpdate()
				// };
				// if (this.Noticepage == 2) {
				// 	this.NoticeData = [{ //公告数据
				// 		dialogNoticeTitle: "积分商城商品通知",
				// 		fContent: "<p>尊敬的各位用户：</p><p>我们将积分商城商品通知，过年快递正常发货！</p>",
				// 		fShopName: "聚材通平台",
				// 		fCreatDate: '2020年11月10日'
				// 	}]
				// 	this.$forceUpdate()
				// };
				// if (this.Noticepage == 3) {
				// 	this.NoticeData = [{ //公告数据
				// 		dialogNoticeTitle: "测试维护通知",
				// 		fContent: "<p>尊敬的各位用户：</p><p>测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知测试维护通知</p>",
				// 		fShopName: "聚材通平台",
				// 		fCreatDate: '2020年11月10日'
				// 	}]
				// 	this.$forceUpdate()
				// }
			},
			//推荐店铺更多点击
			toShopList() {
				this.$router.push({
					path: '/ShopList'
				});
			},
			//进店
			enterTheStore(obj) {
				console.log('obj', obj);
				if (obj.isImage == 'false') {
					return
				}
				if (obj.type == 'userShop') {
					this.$router.push({
						name: 'storeList',
						query: {
							id: obj.fShopUnitID
						}
					});
				} else if (obj.title.indexOf('虚') != -1) {
					if (this.getUserInfo != null) {
						if (this.getUserInfo.fCommerceTypeID != '2') {
							this.$message('个人用户不能开店');
							return;
						}
					}
					var isThereAStore;
					this.ApiRequestPost('api/mall/ebshop/create-requestbill/get-shop-application', {})
						.then(
							res => {
								console.log('获取当前登录人有没有店铺', res);
								if (res.obj) {
									if (res.obj.fApproveStatus == 0) {
										isThereAStore = false;
										this.$router.push({
											path: 'OpenShop'
										});
									} else {
										console.log("开店信息", res)
										isThereAStore = true;
										if (res.obj.fShopTypeID == "2") {
											// 工厂店
											this.$router.push('/OpenShop/OpenShopForm')
										}
										if (res.obj.fShopTypeID == "3") {
											// 经销商
											this.$router.push('/OpenShop/OpenDistributorShopForm')
										}
									}
								} else {
									isThereAStore = false;
									if (this.getUserInfo.fCommerceTypeID != '2') {
										this.$message('个人用户不能开店');
										return;
									} else {
										this.$router.push({
											path: 'OpenShop'
										});
									}
								}
							},
							error => {}
						);

				}
			},
			//获取首页活动
			getCurretIntroduce() {
				this.ApiRequestPostNOMess("/api/mall/ebactivity/introduce/get-curret-introduce").then(
					(result) => {
						console.log("显示首页活动", result.obj);
						if (result.obj) {
							let curretIntroduce = result.obj;
							this.showIntroduceType = curretIntroduce.fShowType;
							if (this.showIntroduceType == 1) {
								this.introducePictureUrl = curretIntroduce.annexList[0].fullFPath
							} else {
								this.introduceContent = curretIntroduce.fContent
							}
							// this.introduceShow = true;
							if (this.$route.path == '/home') {
								this.introduceShow = true;
							}
						} else {
							console.log('this.introduceShow', this.introduceShow);
						}
					},
					(rej) => {}
				);
			},
			//关闭首页活动
			closeIntroduceShow() {
				this.introduceShow = false;
			}

		}
	};
</script>
<style lang="scss" scoped>
 .carouselbg{
  // background: red;
  background-image: url(../assets/hope.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size:1100px 245px;
 }
 .carouselbg1{
  // background: red;
  background-image: url(../assets/hope.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size:910px 245px;
 }
 .carouselbg2{
  // background: red;
  background-image: url(../assets/hope.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size:730px 245px;
 }
 .carouselbg3{
  // background: red;
  background-image: url(../assets/hope.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size:540px 245px;
 }
 .carouselbg4{
  // background: red;
  background-image: url(../assets/hope.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size:360px 245px;
 }
 .carouselbg5{
  // background: red;
  background-image: url(../assets/hope.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size:190px 245px;
 }
  .font-blue {
    color: blue
  }
	/* @import "../assets/css/index.css"; */
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.overscore {
		max-height: 400px;
		overflow-y: auto;
		min-height: 400px;
	}

	.margin-t-5 {
		margin-top: 5px;
	}

	.w100 {
		width: 100px;
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.border-fff {
		border-color: #fff !important;
	}

	.page-box {
		width: 100%;
		background-color: #f8f9fe;
	}

	.width-1100 {
		width: 1100px;
	}

	.main-nav {
		width: 100%;
		position: relative;
	}

	.height-400 {
		height: 400px;
	}

	.coupon-box-one {
		width: 170px;
		height: 90px;
		box-sizing: border-box;
	}

	.coupon-box-two {
		width: 200px;
		height: 90px;
		position: relative;
	}

	.coupon-box-two-r-border {
		width: 1px;
		height: 70px;
		border-right: 1px dashed #ccc;
		position: absolute;
		right: 0;
	}

	.coupon-content-one {
		height: 55px;
	}

	.icon-jiantoux {
		opacity: 0.8;
	}

	.coupon-bg-red {
		background-color: #d60424;
	}

	.coupon-bg-FFCCCC {
		background-color: #ffcccc;
	}

	.coupon-text-red {
		color: #d60424;
	}

	.coupon-content-two-top {
		width: 200px;
	}

	.coupon-content-two-btn {
		width: 75px;
		height: 20px;
		border-radius: 20px;
	}

	.main-nav-left {
		width: 170px;
		position: relative;
		z-index: 1;
	}

	.main-nav-left-inner-box {
		width: 170px;
		// overflow-y: scroll;
    overflow: auto; /* 显示滚动条，根据内容决定是否显示 */
    /* 隐藏默认的滚动条样式 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
	}

	.main-nav-left-item {
		// width: 130px;
		// position: relative;
	}

	.main-nav-left-item:hover {
		@include themify($themes) {
			background-color: themed('themes_color'); //背景主题色
		}
		color: #fff;

		&::v-deep {
			.iconfont {
				color: #fff;
			}

			.main-nav-left-item-right-line-before {
				border-color: #fff;
			}
		}
	}
     .main-nav-left-item:hover .imgBac {
		filter: grayscale(100%)brightness(800%); 
 }
	.main-nav-left-before {
		width: 124px;
		height: 1px;
		border-bottom: 1px dashed #e4e4e4;
		position: absolute;
		top: 0;
		left: calc((170px - 140px) / 2);
	}

	.main-hover-box {
		width: 690px;
		height: 380px;
		overflow-y: auto;
		border: 1px solid #e4e4e4;
		background-color: #f8f9fe;
		position: absolute;
		top: 0;
		left: 170px;
		z-index: 900;
	}

	.main-hover-box-title {
		display: inline-block;
		min-width: 25px;
		height: 24px;
		line-height: 24px;
		padding: 0 15px;
	}

	.main-nav-left-item-height {
		height: 80px;
	}

	.main-nav-left-item-right-line {
		position: relative;
		margin-right: 14px;
	}

	.main-nav-left-item-right-line-before {
		content: '';
		display: inline-block;
		position: absolute;
		height: 12px;
		border-right: 1px solid black;
		top: 4px;
		right: -8px;
	}

	.main-nav-box {
		width: 710px;
	}

	.main-nav-center::v-deep {
		width: 100vw;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

		// 轮播图 左右箭头 影藏  部分
		.el-carousel .el-carousel__arrow {
			// display: none;
		}

		// 轮播图 左箭头 移位置
		.el-carousel__arrow--left {
			left: 10%;
		}

		// 轮播图 右箭头 移位置
		.el-carousel__arrow--right {
			right: 10%;
		}
	}

	.main-nav-center-carousel {
		width: 100%;
		height: 100%;
		background-color: #f2f2f2;
	}

	// 轮播图 左右箭头 影藏  全局
	// .el-carousel::v-deep .el-carousel__arrow {
	// 	display: none;
	// }

	// 轮播图 下方指示器 影藏  全局
	.el-carousel::v-deep .el-carousel__indicators {
		// display: none;
	}

	.main-nav-right {
		height: 400px;
		position: relative;
		z-index: 1;
	}

	.main-nav-right-login-box {
		width: 194px;
		height: 78px;
	}

	.main-nav-right-login-avatar {
		width: 55px;
		height: 55px;
		border-radius: 999px;
		background-color: #d3f2fb;
		overflow: hidden;
	}

	.main-nav-right-login-content {
		width: 136px;
		height: 35px;
	}

	.text-decoration-underline {
		text-decoration: underline;
	}

	.main-nav-right-notice {
		width: 194px;
		height: 113px;
	}

	.main-nav-right-top {
		height: 95px;
		margin-bottom: -5px;
	}

	.notice-box {
		height: 40px;
		// margin-left: -5px;
	}

	.main-nav-right-top-title {
		width: 87px;
		// border: 1px solid #339966;
		line-height: 18px;
		// line-height: 50px;
	}

	.main-nav-right-model {
		width: 194px;
		height: 115px;
	}

	.main-nav-right-model-carousel-box {
		margin-bottom: -8px;
	}

	.main-nav-right-model-carousel::v-deep {
		width: 100%;
		height: 100%;
		padding-top: 5px;

		.el-carousel__indicators {
			display: none;
		}
	}

	.main-nav-right-bottom-item {
		width: 64.5px;
		height: 44px;
	}

	.transform-rotate-negative-90 {
		transform: rotate(-90deg);
	}

	.transform-rotate-positive-90 {
		transform: rotate(90deg);
	}

	.box-row-carousel-one {
		.el-carousel::v-deep .el-carousel__arrow {
			width: 26px;
			border-radius: 0;
			font-size: 22px;
		}

		.el-carousel::v-deep .el-icon-arrow-left {
			margin-top: 2px;
			margin-left: -3px;
		}

		.el-carousel::v-deep .el-icon-arrow-right {
			margin-top: 2px;
			margin-right: -3px;
		}

		.el-carousel::v-deep .el-carousel__arrow--left {
			left: 0;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}

		.el-carousel::v-deep .el-carousel__arrow--right {
			right: 0;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}
	}

	.box-row-carousel-two {
		.el-carousel::v-deep .el-carousel__arrow {
			width: 26px;
			border-radius: 0;
			font-size: 22px;
		}

		.el-carousel::v-deep .el-icon-arrow-left {
			margin-top: 2px;
			margin-left: -3px;
		}

		.el-carousel::v-deep .el-icon-arrow-right {
			margin-top: 2px;
			margin-right: -3px;
		}

		.el-carousel::v-deep .el-carousel__arrow--left {
			left: 0;
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}

		.el-carousel::v-deep .el-carousel__arrow--right {
			right: 0;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}
	}
    .box-row-carousel-shop {
    	.el-carousel::v-deep .el-carousel__arrow {
    		width: 26px;
    		border-radius: 0;
    		font-size: 22px;
    	}
    
    	.el-carousel::v-deep .el-icon-arrow-left {
    		margin-top: 2px;
    		margin-left: -3px;
    	}
    
    	.el-carousel::v-deep .el-icon-arrow-right {
    		margin-top: 2px;
    		margin-right: -3px;
    	}
    
    	.el-carousel::v-deep .el-carousel__arrow--left {
    		left: 0;
    		border-top-right-radius: 30px;
    		border-bottom-right-radius: 30px;
    	}
    
    	.el-carousel::v-deep .el-carousel__arrow--right {
    		right: 32px;
    		border-top-left-radius: 30px;
    		border-bottom-left-radius: 30px;
    	}
    }
	.box-one {
		min-width: 1080px;
		max-width: 1080px;
		min-height: 294px;
		padding: 10px;
		position: relative;
	}

	.box-one-row {
		min-height: 282px;
		overflow-x: auto;
		/* firefox */
		scrollbar-width: none;
		/* IE 10+ */
		-ms-overflow-style: none;
		position: relative;
	}

	.box-one-row::-webkit-scrollbar {
		/* Chrome Safari */
		display: none;
	}

	.box-one-left-arrow-position {
		position: absolute;
		left: 208px;
		top: calc((364px - 50px) / 2);
	}

	.box-one-right-arrow-position {
		position: absolute;
		right: 20px;
		top: calc((364px - 50px) / 2);
	}

	.box-one-row-item-full-image {
		min-width: 168px;
		height: 260px;
		background-color: #ffe5de;
		position: relative;
	}

	.box-one-row-item-full-image img {
		width: 100% !important;
		height: 100% !important;
	}

	.box-one-row-full-image {
		height: 265px;
		position: absolute;
		left: 0;
		top: 0;
	}

	.box-one-row-carousel {
		width: 883px;
	}

	.box-one-row-item-box {
		width: 168px;
		height: 260px;
		position: relative;
	}

	.box-one-row-item {
		width: 168px;
		height: 260px;
		border: 1px solid #f2f2f2;
		position: relative;
	}

	.box-one-row-buoy {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-buoy1 {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-buoy2 {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-buoy3 {
		min-width: 60px;
		width: 60px;
		height: 60px;
		// background-image: linear-gradient(to right, #ff0000, #ff9999);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		padding: 0 10px;
		line-height: 24px;
	}

	.box-one-row-picture-box {
		width: 168px;
		height: 170px;
	}

	.box-one-row-picture {
		// width: 144px;
		height: 148px;
	}

	.box-one-row-content-box {
		width: 148px;
		height: 74px;
		background-color: #ffe5de;
	}

	.box-one-row-content-title {
		width: 148px;
		color: #990000;
	}

	.box-one-row-content-price {
		width: 148px;
		color: #ff0000;
	}

	.box-advert::v-deep {
		height: 90px;
		font-size: 26px;
		font-weight: bold;
		color: #cccccc;

		.el-carousel .el-carousel__indicators {
			display: none;
		}
	}

	.box-two {
		height: 302px;
		position: relative;
	}

	.box-two-left-arrow-position {
		position: absolute;
		left: 0;
		top: calc((364px - 50px) / 2);
	}

	.box-two-right-arrow-position {
		position: absolute;
		right: 0;
		top: calc((364px - 50px) / 2);
	}

	.box-two-top {
		position: relative;
	}

	.box-two-top::before {
		position: absolute;
		content: '';
		display: inline-block;
		width: 1032px;
		border-color: #fff3db #fff3db #fff3db #f8f9fe;
		border-width: 0 48px 48px 20px;
		border-style: solid;
	}

	.box-two-top-content {
		height: 48px;
		padding-left: 30px;
		padding-right: 20px;
		position: relative;
		top: 0;
		left: 0;
	}

	.box-two-top-slash {
		margin: 0 6px 0 4px;
	}

	.box-two-row-carousel ::v-deep {
		width: 1100px;

		.el-carousel__indicators {
			display: none;
		}
	}

	.box-two-row {
		min-height: 245px;
	}

	.box-two-item-box {
		width: 155px;
		height: 225px;
		background-color: #fff3db;
	}

	.box-two-picture-box {
		width: 152px;
		height: 152px;
		background-color: #fff;
		border: 1px solid #f2f2f2;
	}

	.box-two-picture {
		height: 130px;
		width: 130px;
	}

	.box-two-title {
		width: 152px;
		color: #993300;
		// line-height: 20px;
		margin-top: 6px;
	}

	.box-two-integral {
		width: 152px;
		margin-top: 4px;
		display: flex;
		align-items: center;
		height: 20px;
		line-height: 20px;
	}

	.icon-jinbi1 {
		color: #ffcc00;
		margin-right: 10px;
	}

	// 框架三
	.box-three {
		width: 1074px;
		height: 205px;
		padding-bottom: 0;
	}

	.box-three-carousel::v-deep {
		width: 1100px;

		// .el-carousel .el-carousel__indicators {
		// 	// display: none;
		// 	border-radius : 4px;
		// }
		.el-carousel__arrow{
			top:40%;
		}
		.el-carousel__indicator--horizontal .el-carousel__button {
			width: 10px;
			height: 10px;
			background: transparent;
			border: 1px solid #e2e2e2;
			border-radius: 50%;
			opacity: 0.5;
		}

		.el-carousel__indicator--horizontal.is-active .el-carousel__button {
			width: 10px;
			height: 10px;
			background: #cccccc;
			border-radius: 50%;
			opacity: 1;
		}

		.el-carousel__indicators--outside {
			position: absolute;
			bottom: 0;
		}
	}

	.box-three-carousel-item {
		height: 208px;
	}

	.box-three-carousel-item-shop {
		width: 242px;
		height: 65px;
	}

	.box-three-carousel-item-shop-title {
		width: 180px;
	}

	.box-three-carousel-item-shop-img {
		width: 47px;
		height: 47px;
		//border-radius: 75px;
	}

	.box-three-carousel-item-shop-bg-one {
		// background: linear-gradient(150deg, rgba(46, 89, 192, 1) 0%, rgba(46, 89, 192, 1) 0%, rgba(205, 202, 255, 1) 100%, rgba(205, 202, 255, 1) 100%);
		color: #1e1e1e;
		border: 1px solid #f2f2f2;
		margin-bottom: -1px;
		    margin-right: -1px;
		// border-right: none;
		padding: 0 12px;
	}

	.box-three-carousel-item-shop-bg-two {
		// background: linear-gradient(150deg, rgba(224, 39, 53, 1) 0%, rgba(224, 39, 53, 1) 0%, rgba(254, 209, 92, 1) 100%, rgba(254, 209, 92, 1) 100%);
	}

	.box-three-carousel-item-brand {
		width: 127px;
		height: 72px;
	}

	.box-three-carousel-item-brand-img-box {
		width: 100%;
		height: 42px;
	}

	.box-three-carousel-item-brand-img {
		width: 93px;
		height: 36px;
	}

	.box-three-carousel-item-brand-img-border {
		border-right: 1px solid #e4e4e4;
	}

	// 框架四
	.box-four {
		width: 1060px;
		min-height: 346px;
		height: auto;
	}

	.box-four-row {
		margin-top: 10px;
	}

	.box-four-item-box {
		min-width: 1060px;
		min-height: 315px;
		height: auto;
	}

	.box-four-item {
		width: 202px;
		height: 315px;
	}

	.box-four-picture-box {
		width: 202px;
		height: 202px;
	}

	.box-four-picture {
		height: 169px;
	}

	.box-four-content-title {
		height: 40px;
		line-height: 20px;
	}

	.box-four-content-price {
		height: 20px;
		line-height: 20px;
		color: #ff0000;
	}

	.box-four-content-type-box {
		display: flex;
		align-items: center;
	}

	.box-four-content-type {
		min-width: 20px;
		height: 17px;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		padding: 0 4px;
		line-height: 17px;
		border-radius: 2px;
		margin-right: 5px;
	}

	.box-four-content-integral {
		min-width: 20px;
		height: 17px;
		border: 1px solid #ff0000;
		padding: 0 4px;
		line-height: 17px;
		border-radius: 2px;
	}

	.box-four-content-shop {
		margin-top: 2px;
	}

	.box-four-content-shop-text {
		width: 130px;
	}

	.dialogScroll {
		// max-height: 50vh;
		// overflow-y: scroll;
		min-height: 300px;
		max-height: 350px;
		// overflow-x: hidden;
		overflow-y: scroll;
	}

	.color-339966 {
		color: #339966;
	}

	.Notice-title {
		color: #4b8254;
		margin-bottom: 10px;
	}

	.text-right {
		bottom: -65px;
		position: absolute;
		right: 5px;
	}

	.left-footer {
		line-height: 19px;
	}

	.notice-pre-btn,
	.notice-next-btn {
		width: 40px;
		color: #339966;
		border: 1px solid rgba(51, 153, 102, 1);
		line-height: 19px;
		height: 19px;
		padding: 0 5px;
	}

	::v-deep .dialogNotice .el-dialog__headerbtn {
		position: absolute;
		top: -20%;
		right: -60%;
		cursor: pointer;
		font-size: 16px;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		color: #ffffff;
		border: 2px solid #ffffff;
	}

	::v-deep .dialogNotice .el-dialog__headerbtn .el-dialog__close {
		color: #ffffff;
	}

	::v-deep .el-dialog__header {
		padding: 10px 20px 10px;
	}

	::v-deep .el-dialog__body {
		padding: 0px 20px 20px 20px;
	}

	::v-deep .el-pagination .btn-next,
	::v-deep .el-pagination .btn-prev {
		color: #508659;
		border: 1px solid #508659;
		line-height: 30px;
		height: 30px;
		margin-left: 10px;
	}

	.divnum {
		margin-left: 15px;
		line-height: 35px;
		height: 45px;
		color: #508659;
		display: flex;
		margin-bottom: 30px;
	}

	::v-deep .el-pagination span {
		line-height: 30px;
		height: 30px;
	}

	::v-deep .el-pagination .btn-prev {
		padding-right: 0;
	}

	::v-deep .el-pagination .btn-next {
		padding-left: 0;
	}

	::v-deep .el-pagination button {
		padding: 0;
		text-align: center;
		width: 50px;
	}

	.body-mask {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		background-color: #000000;
		opacity: 0.4;
		text-align: center;
		z-index: 98;
	}

	.close-notice-btn {
		top: 5px;
		position: absolute;
		right: -26px;
		z-index: 201;
	}

	.newclose-notice-btn {
		top: 10px;
		position: absolute;
		right: 10px;
		z-index: 201;
	}

	.notice-content {
		width: 900px;
		height: 700px;
		overflow: hidden;
		position: fixed;
		left: 50%;
		top: 55%;
		z-index: 100;
		transform: translate(-50%, -50%);
		// background: url(../assets/notice-bgc.png);
		// background-size: 100% 100%;
	}

	.notice-content-item {
		// overflow-y: scroll;
		// position: relative;
		// left: 55%;
		// top: 58%;
		// z-index: 200;
		// transform: translate(-58%, -55%);
		width: 100%;
		// height:100%;
		background-color: #ffffff;
	}

	.notice-item-box {
		padding-top: 30px;
		// height: 381px;
		margin: 30px;
		position: relative;
	}

	.pegation {
		position: absolute;
		bottom: 0;
	}

	::v-deep .richtext-box p {
		line-height: 26px;
		text-align: left;
		text-indent: 20px;
	}

	::v-deep .el-dialog {
		// margin-top: 40vh !important;
	}

	::v-deep .homeDialog {
		// display: flex !important;
		// align-items: center !important;
		overflow: hidden !important;

		.el-dialog {
			background: transparent;
			box-shadow: none;
			margin: 0px !important;
		}

		.el-dialog__header {
			display: none;
		}

		// .el-dialog__body {
		// 	padding: 30px 20px;
		// }
	}

	::v-deep .introducePict {
		display: flex;

		.el-dialog {
			background: transparent;
			box-shadow: none;
			margin: 0px auto !important;
			max-width: 60vh;
			max-height: 70vh;
		}

		.el-dialog__body {
			margin: 0 auto;
			max-width: 80%;
		}

		.el-dialog__headerbtn {
			font-size: 38px;
			top: 10px;
			right: 015px;

			.el-dialog__close {
				color: #ffffff;
			}
		}
	}

	::v-deep .introduceDialog {
		overflow: hidden !important;

		.el-dialog__body {
			padding: 20px 20px 20px 20px;
		}

	}

	::v-deep .introduceContent {

		.el-dialog {
			box-shadow: none;
			margin: 0px !important;
		}

	}

	.noticeclos {
		// position: absolute;
		// top: -198px;
		// left: 380px;
	}

	.active_mini {
		right: -55px;
		top: 5%;
		position: fixed;
		z-index: 1000;
		width: 7%;
	}

	.active_mini:hover {
		right: 0px;
		top: 5%;
		position: fixed;
		z-index: 1000;
		width: 7%;
	}

	.box-sellout {
		min-width: 70px;
		width: 70px;
		height: 70px;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 100;
		padding: 0 10px;
		line-height: 24px;
	}

	.closeicon {
		color: #fff;
		font-size: 32px;
		top: -33px;
		position: absolute;
		right: -46px;
	}

	::v-deep .dialogNotice .el-dialog__headerbtn {
		position: absolute;
		top: -100%;
		right: -6%;
		cursor: pointer;
		font-size: 16px;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		color: #ffffff;
		border: 2px solid #ffffff;
	}

	.text-el {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 138px;
		color: #A1A1A1;
	}
	.newLaR{
		border:1px  solid #2979FF;
		display: flex;
		line-height: 21px;
		border-radius: 12px;
		justify-content: center;
		margin-top: 3px;
		width: 108px;
	}
	.borderRight{
		border-right:1px solid transparent;
	}
	.borderRightShow{
		order-right:1px solid #f2f2f2;
	}
	.borderTop{
		border-top:1px solid transparent
	}
</style>
